import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const HiringForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    designation: '',
    domains: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const scriptURL = 'https://script.google.com/macros/s/AKfycbyXCoQtnJ_1eCH_b6ZKkPerc9SxWmlcbp17PHEQLe7Uw4DKt69aCJM6UgrHUWr8_Aek/exec'; // Replace with your actual script URL

    // Convert formData object into URL-encoded format
    const formDataEncoded = new URLSearchParams(formData).toString();

    try {
      const response = await fetch(scriptURL, {
        method: 'POST',
        body: formDataEncoded, // Send URL-encoded data
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set content type to URL-encoded
        },
      });

      const result = await response.text(); // Expect a text response from the script
      if (result.includes('Success')) {
        alert('Form submitted successfully!');

        // Clear the form
        setFormData({
          name: '',
          email: '',
          phone: '',
          designation: '',
          domains: ''
        });

      } else {
        alert('Error submitting the form: ' + result);
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      alert('Error submitting the form');
    }
  };

  return (
    <div className="flex items-center justify-center md:py-12 md:px-24" data-aos="fade-left" data-aos-delay="50" data-aos-duration="2000">
      <div className="mx-auto w-full max-w-[550px] bg-white">
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label htmlFor="name" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">Full Name</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              required
            />
          </div>

          <div className="flex flex-col md:flex-row items-center gap-x-2 w-full">
            <div className="mb-5 w-full">
              <label htmlFor="email" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">Company Email</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>

            <div className="mb-5 w-full">
              <label htmlFor="phone" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">Mobile Number</label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter your Mobile Number"
                value={formData.phone}
                onChange={handleChange}
                className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                required
              />
            </div>
          </div>

          <div className="mb-5">
            <label htmlFor="designation" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">Designation</label>
            <input
              type="text"
              name="designation"
              id="designation"
              placeholder="Enter your Designation"
              value={formData.designation}
              onChange={handleChange}
              className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              required
            />
          </div>

          <div className="mb-5">
            <label
              htmlFor="domains"
              className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]"
            >
              Domains you want to hire
            </label>
            <select
              name="domains"
              id="domains"
              value={formData.domains}
              onChange={handleChange}
              className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              required
            >
              <option value="">Select a domain</option> {/* Fix here */}
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Software Development">Software Development</option>
              <option value="Graphic Design">Graphic Design</option>
              <option value="Content Writing">Content Writing</option>
            </select>
          </div>

          <div>
            <button
              type="submit"
              className="uppercase hover:shadow-form w-full rounded-md border border-gray-50 hover:border-orange-500 hover:text-gray-950 duration-500 delay-200 hover:bg-transparent bg-[#f15b2d] py-3 px-8 text-center text-[14px] md:text-[16px] font-semibold text-white outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HiringForm;
