import React from 'react'

import banner from '../../images/blog/blog_banner_6.jpg'
import blogOne from '../../images/blog/blog_6_img1.jpg'
import blogTwo from '../../images/blog/blog_6_img2.jpg'


const BlogSix = () => {
    return (
        <>
            <div className='overflow-hidden'>

                {/* ----------- banner ---------- */}
                <section>

                    <div>
                        <img src={banner} className='w-full object-cover' alt='none' />
                    </div>

                </section>

                {/* -------- blog content -------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 border-t-gray-100 font-[poppins] w-full'>
                    <div className='w-full px-4 md:w-[80%] mx-auto '>

                        <div className='flex flex-col  gap-y-1 md:gap-y-3 '>
                            <h1 className='uppercase text-start font-bold text-[16px] md:text-[24px]'>Why Choose Digital Marketing Training in Chennai at The Learnbot?</h1>
                        </div>

                        <div className='flex justify-center items-center mt-6 w-full'>
                            <div className='flex flex-col gap-y-8 md:gap-y-12 w-full'>

                                <div>
                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                        In today’s digital era, businesses are continuously shifting their marketing strategies to online platforms, making Digital Marketing Training
                                        in Chennai one of the most sought-after skills. Chennai is home to a growing tech ecosystem, and The Learnbot is at the forefront of providing
                                        comprehensive digital marketing courses to meet this demand. In this blog, we’ll explore why The Learnbot is the best choice for aspiring digital
                                        marketers in Chennai, covering everything from the benefits of training to career opportunities.
                                    </p>
                                </div>

                                {/* -------------------- first content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex flex-col gap-y-6 md:flex-row items-center justify-between w-full'>

                                            <div className='w-full md:w-[50%] mx-auto'>

                                                <div className='flex flex-col gap-y-2 md:gap-y-4'>
                                                    <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>1. The Growing Demand for Digital Marketing Skills in Chennai</h1>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        Chennai, often referred to as the "Detroit of India," has rapidly transformed into a hub for IT and digital services. With the rise of startups, tech companies,
                                                        and global enterprises setting up shop in the city, there’s a huge demand for professionals skilled in Digital Marketing Training in Chennai. From local businesses
                                                        to large enterprises, the need for effective digital strategies is evident.
                                                    </p>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        If you are looking to build a career in the digital space, taking up Digital Marketing Training in Chennai at The Learnbot is your first step towards success.
                                                    </p>

                                                </div>
                                            </div>

                                            <div className='w-full md:w-[50%] mx-auto'>
                                                <div>
                                                    <img src={blogOne} className='w-full md:w-[90%] rounded-md mx-auto object-cover' alt='image' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {/* -------------------- second content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>2. Why Chennai is the Ideal Place for Digital Marketing Training</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Chennai offers a perfect blend of opportunities for learning and career growth in the digital marketing industry. With numerous digital agencies, IT companies,
                                                    and global corporations present in the city, there are ample job opportunities for those equipped with the right skills.
                                                </p>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Digital Marketing Training in Chennai also offers the added advantage of being in close proximity to numerous networking events, workshops, and
                                                    conferences. These events provide a chance to stay updated with the latest industry trends and network with professionals. The Learnbot takes full
                                                    advantage of Chennai’s thriving digital marketing ecosystem by collaborating with industry leaders to offer cutting-edge training.
                                                </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {/* -------------------- thrid content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>3. Overview of The Learnbot’s Digital Marketing Program</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6  md:leading-7'>
                                                    The Learnbot offers a 4-month Digital Marketing Training in Chennai, designed to equip students with both foundational and advanced skills in digital marketing. The course covers a wide
                                                    range of topics including SEO, SEM, SMM, content marketing, email marketing, analytics, and more. Whether you’re a beginner or a professional looking to enhance your skills, our program
                                                    is tailored to meet your needs.
                                                </p>

                                                <p className='text-[12px] md:text-[14px] leading-6  md:leading-7'>
                                                    By enrolling in Digital Marketing Training in Chennai at The Learnbot, students get hands-on experience through real-world projects. The curriculum is designed to be interactive, ensuring
                                                    that students don’t just learn the theory but also get practical experience that prepares them for real-world challenges.
                                                </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {/* -------------------- Fourth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex flex-col md:flex-row gap-y-6 items-center justify-between w-full'>

                                            <div className='w-full md:w-[50%] mx-auto'>

                                                <div className='flex flex-col gap-y-4'>
                                                    <h1 className='uppercase text-start font-bold  text-[14px] md:text-[18px] text-sky-700'>4. Flexible Learning Options: Classroom and Online</h1>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        One of the key benefits of choosing The Learnbot is the flexibility it offers. The Learnbot provides both Digital Marketing Training in Chennai
                                                        as well as an online option, making it accessible to anyone regardless of their location.
                                                    </p>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        For those who prefer classroom learning, our Digital Marketing Training in Chennai is conducted in a professional setting with state-of-the-art
                                                        infrastructure. Our classrooms are designed to foster interactive learning, where students can engage with trainers and peers for a more personalized experience.
                                                    </p>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        For working professionals or those unable to attend in-person sessions, our online training offers the same quality of education with live webinars, recorded
                                                        sessions, and real-time interactions with instructors. This flexibility ensures that learning doesn’t stop, regardless of where you are.
                                                    </p>

                                                </div>
                                            </div>

                                            <div className='w-full md:w-[50%] mx-auto'>
                                                <div>
                                                    <img src={blogTwo} className='w-full md:w-[90%] rounded-md mx-auto object-cover' alt='image' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/* -------------------- fifth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>5. Experienced Instructors and Industry Experts</h1>

                                                <p className='text-[12px] md:text-[14px]  leading-6 md:leading-7'>
                                                    At The Learnbot, we believe that the quality of education is directly tied to the quality of instructors. That’s why our Digital Marketing Training in
                                                    Chennai is conducted by industry veterans with years of experience in the field. These experts bring practical insights and up-to-date knowledge of the latest trends in digital marketing.
                                                </p>

                                                <p className='text-[12px] md:text-[14px]  leading-6 md:leading-7'>
                                                    Our instructors are not just teachers; they are active professionals in the digital marketing world, ensuring that students receive the most relevant and timely education. With years of experience
                                                    under their belts, our instructors provide real-world examples and case studies that enhance the learning experience.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                </div>


                                {/* -------------------- sixth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>6. Job Placement Assistance and Career Support</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    The ultimate goal of Digital Marketing Training in Chennai at The Learnbot is to ensure that our students land their dream jobs. To achieve this,
                                                    we offer extensive job placement assistance and career support to all our students. Our placement team works closely with top digital marketing agencies,
                                                    IT firms, and multinational companies to ensure that our students are well-prepared for interviews and job placements.
                                                </p>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Additionally, students receive guidance on resume building, portfolio creation, and interview preparation, giving them an edge in the competitive job market.
                                                    Digital Marketing Training in Chennai at The Learnbot provides a complete career solution, ensuring that students are well-equipped to start their digital
                                                    marketing careers.

                                                </p>

                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {/* -------------------- seventh content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>7. Why Choose The Learnbot for Digital Marketing Training in Chennai?</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    There are many reasons why The Learnbot stands out as the best institute for Digital Marketing Training in Chennai:

                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3 md:list-inside ml-3 md:ml-0  font-[roboto]'>
                                            <li>Comprehensive Curriculum: Our course covers all aspects of digital marketing, ensuring that students have a 360-degree understanding of the field.</li>
                                            <li>Hands-On Experience: Real-world projects and assignments prepare students for actual digital marketing roles.</li>
                                            <li>Industry Connections: Our strong ties with industry leaders ensure that students have access to the latest trends and job opportunities.</li>
                                            <li>Flexible Learning Options: Both classroom and online training options are available, making it easier for students to learn at their own pace.</li>
                                            <li>Flexible Learning Options: Both classroom and online training options are available, making it easier for students to learn at their own pace.</li>
                                            <li>Placement Assistance: Our dedicated placement team ensures that students are well-prepared to enter the job market with confidence.</li>
                                        </ul>
                                    </div>

                                </div>


                                {/* -------------------- eighth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>8. Conclusion</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Choosing the right institute for Digital Marketing Training in Chennai is crucial for building a successful career in this fast-growing field.
                                                    The Learnbot offers everything you need to become a skilled digital marketer – from expert instructors and a comprehensive curriculum to flexible
                                                    learning options and job placement assistance. Whether you’re just starting or looking to advance your career, The Learnbot’s Digital Marketing
                                                    Training in Chennai is the perfect choice for you.
                                                </p>


                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Digital Marketing Training in Chennai at The Learnbot is more than just a course; it’s a pathway to a successful and rewarding career. Enroll today
                                                    and take the first step toward becoming a digital marketing expert.

                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </>
    )
}

export default BlogSix