import React from 'react'

import banner from '../../images/blog/blog_banner_3.jpg'
import blogOne from '../../images/blog/blog_3_img1.jpg'
import blogTwo from '../../images/blog/blog_3_img2.jpg'


const BlogThree = () => {
    return (
        <>
            <div className='overflow-hidden'>

                {/* ----------- banner ---------- */}
                <section>

                    <div>
                        <img src={banner} className='w-full object-cover' alt='none' />
                    </div>

                </section>

                {/* -------- blog content -------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 border-t-gray-100 font-[poppins] w-full'>
                    <div className='w-full px-4 md:w-[80%] mx-auto '>

                        <div className='flex flex-col  gap-y-1 md:gap-y-3 '>
                            <h1 className='uppercase text-start font-bold text-[16px] md:text-[24px]'>Master Digital Marketing with Online Training from The Learnbot</h1>
                        </div>

                        <div className='flex justify-center items-center mt-6 w-full'>
                            <div className='flex flex-col gap-y-8 md:gap-y-12 w-full'>

                                <div>
                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                        In today’s increasingly connected world, the demand for digital marketers has never been higher.
                                        Whether you’re looking to switch careers, boost your current business, or start something new, getting
                                        the right skills is crucial. That’s where Digital Marketing Online Training from The Learnbot comes in.
                                        Our comprehensive program is designed to give you the knowledge and skills needed to succeed in this rapidly
                                        changing industry, all from the comfort of your home.
                                    </p>
                                </div>

                                {/* -------------------- first content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex flex-col gap-y-6 md:flex-row items-center justify-between w-full'>

                                            <div className='w-full md:w-[50%] mx-auto'>

                                                <div className='flex flex-col gap-y-2 md:gap-y-4'>
                                                    <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>1. The Importance of Digital Marketing Today</h1>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        The digital landscape has fundamentally transformed how businesses operate. With the advent of social media, SEO, PPC, content marketing, and email marketing,
                                                        companies have shifted their focus to online platforms to connect with customers. This shift has led to an increased demand for professionals skilled in digital
                                                        marketing. Digital Marketing Online Training from The Learnbot is designed to equip you with these highly sought-after skills.
                                                    </p>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        From small startups to multinational corporations, businesses rely on digital marketing strategies to drive growth, increase brand awareness, and generate leads.
                                                        Enrolling in Digital Marketing Online Training ensures you’re equipped with the tools and strategies needed to succeed in any industry.
                                                    </p>

                                                </div>
                                            </div>

                                            <div className='w-full md:w-[50%] mx-auto'>
                                                <div>
                                                    <img src={blogOne} className='w-full md:w-[90%] rounded-md mx-auto object-cover' alt='image' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                                {/* -------------------- second content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>2. Why Choose Digital Marketing Online Training?</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Digital Marketing Online Training offers a level of flexibility that traditional classroom settings can’t match. With The Learnbot’s online program,
                                                    you can learn at your own pace, fitting your studies around your schedule. This is especially beneficial for working professionals or students who want
                                                    to upskill but don’t have the time for a full-time classroom course.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <h2 className='font-semibold text-[14px] md:text-[16px] '>Here’s why Digital Marketing Online Training is the best choice:</h2>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc  space-y-3  md:list-inside ml-3 md:ml-0 font-[roboto]'>
                                            <li>Flexible learning: Learn at your convenience, from anywhere.</li>
                                            <li>Comprehensive curriculum: Covers everything from SEO and SEM to social media marketing and email campaigns.</li>
                                            <li>Expert instructors: Learn from industry professionals with real-world experience.</li>
                                            <li>Hands-on projects: Gain practical experience through real-world assignments and projects.</li>

                                        </ul>
                                    </div>
                                </div>


                                {/* -------------------- thrid content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>3. Course Overview: What You Will Learn</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6  md:leading-7'>
                                                    The Learnbot’s Digital Marketing Online Training program offers an all-encompassing curriculum designed to provide a deep understanding of the digital marketing ecosystem. Here are some of the core areas covered in the course:
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>

                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3  md:list-inside ml-3 md:ml-0 font-[roboto]'>
                                            <li>Search Engine Optimization (SEO): Learn how to optimize websites to rank higher on search engines like Google, driving organic traffic to websites.</li>
                                            <li>Social Media Marketing (SMM): Master the art of using platforms like Facebook, Instagram, LinkedIn, and Twitter for brand promotion and engagement.</li>
                                            <li>Pay-Per-Click Advertising (PPC): Dive into the world of paid search advertising and learn how to manage effective Google Ads campaigns.</li>
                                            <li>Content Marketing: Discover how to create compelling, valuable content that resonates with your target audience and drives conversions.</li>
                                            <li>Email Marketing: Learn the power of email marketing campaigns and how to manage them to generate leads and retain customers.</li>
                                            <li>Web Analytics: Understand the tools and techniques to analyze the performance of digital marketing efforts using platforms like Google Analytics.</li>

                                        </ul>
                                        <p className='text-[12px] md:text-[14px]'>Throughout the course, students in the Digital Marketing Online Training program will have access to real-world case studies, assignments, and live projects, ensuring they graduate with hands-on experience.</p>
                                    </div>
                                </div>


                                {/* -------------------- Fourth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex flex-col md:flex-row gap-y-6 items-center justify-between w-full'>

                                            <div className='w-full md:w-[50%] mx-auto'>

                                                <div className='flex flex-col gap-y-4'>
                                                    <h1 className='uppercase text-start font-bold  text-[14px] md:text-[18px] text-sky-700'>4. Who Should Enroll in Digital Marketing Online Training?</h1>

                                                    <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                        Digital Marketing Online Training is ideal for anyone looking to build or advance their career in digital marketing. Whether you are a business owner looking to promote your brand,
                                                        a marketing professional wanting to upskill, or a student eager to enter the digital world, The Learnbot’s online program is suited for all.
                                                    </p>

                                                </div>
                                            </div>

                                            <div className='w-full md:w-[50%] mx-auto'>
                                                <div>
                                                    <img src={blogTwo} className='w-full md:w-[90%] rounded-md mx-auto object-cover' alt='image' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <h2 className='font-semibold text-[14px] md:text-[16px] '>Some of the target audiences include:</h2>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3 md:list-inside ml-3 md:ml-0 font-[roboto]'>
                                            <li>Business Owners: Learn how to promote your business online and drive sales.</li>
                                            <li>Marketing Professionals: Upgrade your skills and stay relevant in a constantly evolving field.Marketing Professionals: Upgrade your skills and stay relevant in a constantly evolving field.</li>
                                            <li>Students: Break into the lucrative field of digital marketing with a comprehensive understanding of key tools and techniques.</li>
                                            <li>Freelancers: Increase your marketability and client base by mastering digital marketing.</li>

                                        </ul>
                                    </div>
                                </div>

                                {/* -------------------- fifth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>5. What Sets The Learnbot’s Digital Marketing Online Training Apart?</h1>

                                                <p className='text-[12px] md:text-[14px]  leading-6 md:leading-7'>
                                                    There are plenty of online courses available, but The Learnbot’s Digital Marketing Online Training stands out for several reasons:
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3 md:list-inside ml-3 md:ml-0  font-[roboto]'>
                                            <li>Live interactive sessions: Learn in real-time with live instructor-led sessions, where you can ask questions and get instant feedback.</li>
                                            <li>Project-based learning: The Learnbot emphasizes hands-on learning through real-world projects and assignments.</li>
                                            <li>Lifetime access to materials: Students have lifetime access to recorded sessions, study materials, and resources to review at their convenience.</li>
                                            <li>Industry-recognized certification: Graduates of the Digital Marketing Online Training program receive a certification that is highly regarded by employers.</li>
                                            <li>Dedicated career support: We offer career guidance, resume building, and job placement assistance to ensure you are fully prepared to enter the workforce.</li>
                                        </ul>
                                    </div>
                                </div>


                                {/* -------------------- sixth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>6. Flexible and Accessible Learning</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    One of the major advantages of Digital Marketing Online Training is its flexibility. You don’t need to relocate or commute; you can learn
                                                    from anywhere with a good internet connection. This is perfect for individuals with busy schedules or those who may not live near a digital
                                                    marketing institute. The Learnbot’s Digital Marketing Online Training makes learning accessible to anyone interested in building a career
                                                    in digital marketing.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <h2 className='font-semibold text-[14px] md:text-[16px] '>Additionally, our online platform allows students to:</h2>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3 md:list-inside ml-3 md:ml-0  font-[roboto]'>
                                            <li>Revisit recorded sessions at their convenience.</li>
                                            <li>Participate in discussion forums to engage with peers and instructors.</li>
                                            <li>Access study materials and resources 24/7.</li>

                                        </ul>
                                    </div>
                                </div>


                                {/* -------------------- seventh content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>7. Job Opportunities After Completing Digital Marketing Online Training</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    The demand for digital marketing professionals continues to grow, with companies looking for individuals who can drive their online presence. After completing Digital
                                                    Marketing Online Training, a wide range of job roles will be open to you, including:
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex flex-col gap-y-3'>
                                        <ul className='text-[12px] md:text-[14px] font-semibold list-disc space-y-3 md:list-inside ml-3 md:ml-0  font-[roboto]'>
                                            <li>SEO Specialist: Optimize websites to increase visibility and search engine ranking.</li>
                                            <li>Social Media Manager: Create and manage social media campaigns that engage followers and promote brand awareness.</li>
                                            <li>Content Marketer: Develop content strategies that align with business goals and engage the target audience.</li>
                                            <li>PPC Specialist: Manage paid search campaigns to drive traffic and conversions.</li>
                                            <li>Digital Marketing Manager: Oversee all digital marketing efforts for a business or organization.</li>
                                        </ul>
                                        <p className='text-[12px] md:text-[14px]'>The Learnbot’s Digital Marketing Online Training program provides the skills needed to excel in these roles, ensuring you’re job-ready upon graduation.</p>

                                    </div>
                                </div>


                                {/* -------------------- eighth content --------------- */}

                                <div className='flex flex-col gap-y-6'>

                                    <div className='w-full mx-auto'>
                                        <div className='flex items-center justify-between w-full'>
                                            <div className='flex flex-col gap-y-4'>
                                                <h1 className='uppercase text-start font-bold text-[14px] md:text-[18px] text-sky-700'>8. Conclusion</h1>

                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    If you’re serious about mastering SEO and building a successful career in digital marketing, enrolling at The
                                                    Learnbot’s Digital Marketing Training Institute in Chennai is the right step. Our comprehensive SEO training,
                                                    real-world projects, and expert guidance ensure you’re job-ready from day one. Don’t miss out on the opportunity
                                                    to learn SEO from the best in the industry—sign up today!
                                                </p>
                                                <p className='text-[12px] md:text-[14px] leading-6 md:leading-7'>
                                                    Enroll today in The Learnbot’s Digital Marketing Online Training and take the first step toward a thriving career in digital marketing.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </div>
        </>
    )
}

export default BlogThree