import React from 'react'
import Slider from "react-slick";


import countryOne from '../images/country/india.jpg'
import countryTwo from '../images/country/Bangladesh.jpg'
import countryThree from '../images/country/Brazil.jpg'
import countryFour from '../images/country/canada.jpg'
import countryFive from '../images/country/Egypt.jpg'
import countrySix from '../images/country/France.jpg'
import countrySeven from '../images/country/germany.jpg'
import countryNine from '../images/country/Italy.jpg'
import countryTen from '../images/country/Philippine.jpg'
import countryElven from '../images/country/Russia.jpg'
import countryTwele from '../images/country/Singapore.jpg'
import countryThrtieen from '../images/country/South Korea.jpg'
import countryFouteen from '../images/country/Turkey.jpg'
import countryFitien from '../images/country/UAE.jpg'
import countrySixten from '../images/country/UK.jpg'
import countrySeventeen from '../images/country/USA.jpg'



import domainOne from '../images/hire_seo.jpg'

import seo from '../images/animation/seo.gif'
import smo from '../images/animation/smo.gif'
import sem from '../images/animation/sem.gif'
import digital from '../images/animation/digital.gif'
import analiys from '../images/animation/analysit.gif'
import smm from '../images/animation/smm.gif'
import seo_ex from '../images/animation/seo_excutive.gif'
import smo_ex from '../images/animation/smo_ex.gif'

import banner from '../images/banner/DGB2.jpg'

import hireOne from '../images/icons/hire_certificate.png'
import hireTwo from '../images/icons/industry_lead.png'
import hireThree from '../images/icons/Requirement.png'
import hireFour from '../images/icons/support.png'
import hireFive from '../images/icons/hiring.png'
import hireSix from '../images/icons/cost.png'


import { MdOutlinePhoneAndroid } from "react-icons/md";
import HireTabs from '../components/HireTaps';

import HireFAQ from '../components/HireFAQ';
import HiringForm from '../components/HiringForm';


const HireFromUs = () => {

    var settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>

            <div className='font-[poppins] overflow-hidden'>


                <div className='fixed top-0 w-full h-screen -z-20'>
                    <img src={banner} className='w-full h-screen md:h-auto object-cover' />
                </div>

                <section>
                    <div className='md:pt-10 md:pb-10 '>
                        <div className=' w-full px-4 md:w-[90%] mx-auto bg-white border border-gray-200 rounded-lg pb-10'>

                            <div className='flex flex-col  md:flex-row gap-y-8 items-start '>

                                <div className='w-full  md:w-[50%] mx-auto'>

                                    <div className='md:px-14 py-4 md:py-12'>
                                        <div>
                                            <h3 className='font-bold  uppercase mb-4 md:mb-8 mt-6 md:mt-10 text-[24px] md:text-[38px] text-teal-700' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">Hire Qualified <span className=''>Experts</span> </h3>

                                            <p className='text-[12px] md:text-[14px] text-gray-700' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                                Partner with India's leading online education platform, trusted by students from over 30 countries.
                                                Our innovative courses are crafted and taught by industry experts, ensuring top-tier learning experiences.
                                            </p>

                                            <ul className='font-semibold text-[12px] md:text-[16px] list-disc ml-4 space-y-2 mt-6 md:mt-8 ' data-aos='fade-right' data-aos-delay="50" data-aos-duration="3000">
                                                <li>Over 1000 International Hiring Partners</li>
                                                <li>Training in Industry-Relevant Skills</li>
                                                <li>Prepared to Contribute from Day One</li>
                                                <li>Expert-Led, Practical Learning</li>
                                                <li>Tailored Programs for Immediate Impact</li>
                                            </ul>

                                            <div className='mt-8 md:mt-14' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                                <a href="tel:+919884683888" target="_blank" rel="noopener noreferrer" className="">

                                                    <button className='bg-[#2384b3] text-white shadow-md drop-shadow-md h-9 md:h-10 pl-3 rounded-md flex items-center gap-x-3  font-[poppins] text-[12px] md:text-[16px] hover:scale-105 duration-500'> Talk To Placement Officer <div className='px-3 bg-gray-950  rounded-r-md'><MdOutlinePhoneAndroid className='text-white h-9 md:h-10    text-[22px] font-bold' /> </div>  </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>


                                </div>

                                <div className='w-full md:w-[50%] mx-auto'>
                                    
                                    <HiringForm />

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* ------------------ Learn from around the world ------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-blue-50'>

                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-10 md:mb-16' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                            <h3 className=' text-[20px] md:text-[28px] text-center font-bold'>Learn from around the world</h3>
                        </div>

                        <div>
                            <div className="slider-container" data-aos='fade-up' data-aos-delay="50" data-aos-duration="3000">
                                <Slider {...settings}>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryOne} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryTwo} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryThree} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryFour} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryFive} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countrySix} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countrySeven} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryNine} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryTen} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryElven} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryTwele} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryThrtieen} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryFouteen} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countryFitien} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countrySixten} className='w-full object-cover' />
                                        </div>
                                    </div>
                                    <div className='flex justify-center px-3 md:px-14'>
                                        <div>
                                            <img src={countrySeventeen} className='w-full object-cover' />
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>

                    </div>

                </section>

                {/* -------------------- Domain you can hire from us --------------- */}

                {/* <section className=' pt-10 md:pt-20 pb-10 md:pb-20 bg-[#FAF9F6] '>

                    <div className='w-full  md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center mb-6 md:mb-12 px-4'>
                            <h3 className='text-[20px] md:text-[28px] text-center font-bold'>Domain you can hire from us</h3>
                        </div>

                        <div className='flex justify-center items-center'>
                            <div className='grid grid-cols-3 gap-x-4 gap-y-10'>

                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>

                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>
                                <div>
                                    <div className='flex justify-center'>
                                        <img src={domainOne} className='w-[80%] object-cover rounded-md drop-shadow-md shadow-md' />
                                    </div>
                                </div>


                            </div>

                        </div>

                    </div>
                </section> */}

                {/* ---------------------- Roles you can recruit from us ---------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-white'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center gap-y-3 mb-8 md:mb-14'>
                            <h3 className='text-[20px] md:text-[28px] text-center font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> Roles you can recruit from us </h3>
                            <p className='text-center text-[10px] md:text-[14px] w-full md:w-[80%] mx-auto' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> Explore a diverse selection of specialized digital marketing roles available for recruitment through our platform. From strategists to creatives, we've got the talent to elevate your brand. </p>
                        </div>

                        <div>

                            <div className='grid grid-cols-2 md:grid-cols-4 gap-y-6 md:gap-y-16 gap-x-8 md:gap-x-10'>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={seo} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SEO Intern</h3>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={smo} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SMO Intern</h3>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={sem} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SEM Intern</h3>
                                        </div>
                                    </div>

                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={digital} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>Digital Marketing Intern</h3>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={analiys} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>Direct Marketing Analyst</h3>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={smm} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SMM Intern</h3>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={seo_ex} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SEO Executive</h3>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col justify-center items-center gap-y-2' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={smo_ex} className='w-16 md:w-24 object-cover' />
                                        </div>
                                        <div>
                                            <h3 className='font-semibold text-center text-[12px] md:text-[16px] text-gray-800'>SMO Executive</h3>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                {/* ------------------- Why hire from us? ------------------ */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#f8f8ff]'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center mb-8 md:mb-14 gap-y-3'>
                            <h3 className='text-[20px] md:text-[28px] text-center font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000"> Why hire from us? </h3>
                            <p className='text-center text-[12px] md:text-[14px]' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">Select from a rigorously trained and certified talent pool of marketing, design, and development experts to elevate your company.</p>
                        </div>

                        <div className='flex justify-center items-center'>

                            <div className='grid grid-cols-1 md:grid-cols-3 gap-x-16  gap-y-12'>

                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireOne} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Certified with Industry Recognized Credentials</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireTwo} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Diverse Talent Pool Trained by Leading Industry Experts</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireThree} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Pre-Trained to Meet Specific Requirements</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireFour} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Lifetime Support for Individual or Bulk Hiring</p>
                                        </div>

                                    </div>
                                </div>

                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireFive} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Eliminates Post-Hiring Training Needs</p>
                                        </div>

                                    </div>
                                </div>



                                <div className='bg-gray-50 py-5 px-4 md:px-8 rounded-md drop-shadow-lg ' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col justify-center items-center gap-y-3' >

                                        <div>
                                            <img src={hireSix} className='w-14 md:w-16 object-cover' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[11px] md:text-[14px] font-semibold'>Zero-Cost Deployment</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                {/* -------------------- How We Guarantee Talent is Ready to Perform -------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-white'>
                    <div className='w-full  md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center mb-5 md:mb-14 gap-y-3 px-4 md:px-0'>
                            <h3 className='text-[16px] md:text-[28px] text-center font-bold' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> How We Ensure Talent is Ready to Excel </h3>
                            <p className='text-center text-[10px] md:text-[14px] w-full md:w-[95%] font-[roboto] ' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                                At Thelearnbot.com, we focus on delivering job-ready talent by combining rigorous training with real-world experience. Our comprehensive
                                curriculum and hands-on projects ensure that graduates are equipped to deliver exceptional results from the start. We tailor our training
                                to align with industry standards and specific business needs, guaranteeing that our talent is not only skilled but also adaptable to your
                                unique requirements.
                            </p>
                        </div>

                        <div className='px-1 ' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                            <div>
                                <HireTabs /> 
                            </div>
                        </div>

                    </div>
                </section>


                {/* ---------------------- FAQ  --------------------- */}

                <section className='bg-gray-200 pt-10  md:pt-20 pb-10 md:pb-20'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center mb-8 gap-y-3'>
                            <h3 className='text-[20px] md:text-[28px] text-center font-bold'>Frequently Asked Questions </h3>
                        </div>

                        <div className=''>

                            <div>
                                <HireFAQ />
                            </div>

                        </div>


                    </div>
                </section>



            </div>

        </>
    )
}

export default HireFromUs