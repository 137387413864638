import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const HireFAQ = () => {
    const [open, setOpen] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? null : value);
    };

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="bg-transparent  md:pt-6  md:px-6 w-full md:w-[95%] mx-auto font-[poppins]">

            <div className="grid grid-cols-1 md:grid-cols-2  gap-x-14 gap-y-3 md:gap-y-10">

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(1)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Why hire from The learnbot.com?

                            <Icon id={1} open={open} />
                        </div>
                        {open === 1 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800  text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our graduates are equipped with comprehensive digital marketing skills, hands-on experience, and industry-recognized certification.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(2)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What kind of roles can your graduates fill?
                            <Icon id={2} open={open} />
                        </div>
                        {open === 2 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our graduates are prepared for roles such as Digital Marketing Specialist, SEO Analyst, Content Marketer, Social Media Manager, and more.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(3)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How do I recruit from Thelearnbot.com?
                            <Icon id={3} open={open} />
                        </div>
                        {open === 3 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You can contact us directly to discuss your hiring needs and we'll connect you with suitable candidates.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(4)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Are your graduates job-ready?
                            <Icon id={4} open={open} />
                        </div>
                        {open === 4 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, our rigorous training and real-world projects ensure that our graduates are fully prepared to excel in their roles.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(5)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What is the success rate of your graduates?
                            <Icon id={5} open={open} />
                        </div>
                        {open === 5 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our graduates have a high success rate in securing jobs within the digital marketing industry.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(6)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Do you offer internships?
                            <Icon id={6} open={open} />
                        </div>
                        {open === 6 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, we collaborate with leading companies to provide internship opportunities for our students.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(7)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Can I interview candidates before hiring?
                            <Icon id={7} open={open} />
                        </div>
                        {open === 7 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Absolutely. We facilitate interviews and offer guidance in selecting the right candidate for your organization.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(8)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What support do you offer to employers?
                            <Icon id={8} open={open} />
                        </div>
                        {open === 8 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    We provide ongoing support to employers, including candidate assessments and recommendations based on your specific needs.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(9)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How can I stay updated on new graduates?
                            <Icon id={9} open={open} />
                        </div>
                        {open === 9 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Join our employer network to receive updates on new graduates and upcoming hiring events.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white drop-shadow-md shadow-md border-b border-gray-400  rounded-md cursor-pointer" onClick={() => handleOpen(10)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What are the hiring terms?
                            <Icon id={10} open={open} />
                        </div>
                        {open === 10 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    We offer flexible hiring terms to suit your company's requirements. Contact us for more details.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default HireFAQ;
