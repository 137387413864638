// PrivacyPolicy.js
import React from "react";

const Terms = () => {
    return (

        <>
            <div className="pt-10 md:pt-20  pb-10 md:pb-20 bg-gray-50">

                <div className="px-4 md:p-6 text-gray-900 font-[poppins]">
                    <div className="w-full md:w-[80%]  mx-auto">
                        <h1 className="text-[20px] md:text-[28px] font-bold mb-6 text-center uppercase">Terms & Conditions</h1>

                        <div className="flex flex-col  gap-y-3 md:gap-y-6">
                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2"> Acceptance of Terms</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    By accessing or using our website, you agree to be bound by these Terms and Conditions.
                                    If you disagree with any part of the terms, you may not access our website or services.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Use of the Website</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    You agree not to use any unauthorized methods (such as robots, scraping, or other means) to access
                                    or extract data from the site.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Intellectual Property</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    All content on this website, including but not limited to text, graphics, logos, and images, is the property of [Your Company Name]
                                    or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or use any part of the
                                    website without prior written permission.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Disclaimer of Warranties</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    The website is provided "as is" and "as available," without any warranties of any kind. We do
                                    not guarantee the accuracy, completeness, or reliability of any content or services on the website.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2"> Limitation of Liability</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    [Your Company Name] shall not be liable for any damages arising from your use of the website or inability to use the site,
                                    including but not limited to direct, indirect, incidental, punitive, or consequential damages.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Links to Other Websites</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    Our website may contain links to third-party websites or services. We are not responsible for the
                                    content, privacy policies, or practices of any third-party websites.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Changes to the Terms</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We reserve the right to modify these Terms at any time. Changes will be posted on this page with an updated
                                    date. By continuing to use the site after changes are posted, you accept the revised Terms and Conditions.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Governing Law</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                These Terms and Conditions are governed by and construed in accordance with the laws of
                                 [Your Country/State], without regard to its conflict of law provisions.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default Terms;
