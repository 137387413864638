import React from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Home from './pages/Home';
import Admin from './admin/Admin';
import Footer from './components/Footer';
import Course from './pages/Course';
import Contact from './pages/Contact';
import About from './pages/About';
import Blog from './pages/Blog';
import AdminHeader from './admin/AdminHeader';
import HireFromUs from './pages/HireFromUs';
import CourseOne from './pages/CourseOne'; // Fixed this
import CourseTwo from './pages/CourseTwo'; // Fixed this
import CourseThree from './pages/CourseThree';
import CreateBlog from './admin/CreateBlog';
import EditBlog from './admin/EditBlog';
import Login from './admin/Login';
import Whatsapp from './components/Whatsapp';
import DummyBlog from './pages/DummyBlog';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';

import BlogOne from './pages/blog/BlogOne';
import BlogTwo from './pages/blog/BlogTwo';
import BlogThree from './pages/blog/Blogthree';
import BlogFour from './pages/blog/BlogFour';
import BlogFive from './pages/blog/BlogFive';
import BlogSix from './pages/blog/BlogSix';

const UserLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const AdminLayout = () => {
  return (
    <>
      <AdminHeader />
      <Outlet />
    </>
  );
};

// PrivateRoute component to handle authentication
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token'); // Adjust this to your token storage method
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <>
      <Router>
        <ToastContainer />
        <Whatsapp />
        <Routes>
          {/* UserLayout */}
          <Route path="/" element={<UserLayout />}>
            <Route index element={<Home />} />
            <Route path="/course" element={<Course />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<DummyBlog />} />
            <Route path='/blog/how-social-media-marketing-training-at-the-learnbot-can-elevate-your-career' element={<BlogOne />} />
            <Route path='/blog/learn-seo-from-the-best-digital-marketing-training-institute-in-chennai-the-learnbot' element={<BlogTwo />} />
            <Route path='/blog/master-digital-marketing-with-online-training-from-the-learnbot'  element={<BlogThree />} />
            <Route path='/blog/master-social-media-marketing-at-the-best-digital-marketing-training-institute-in-chennai–the-learnbot' element={<BlogFour />} />
            <Route path='/blog/learn-google-ads-at-the-best-digital-marketing-training-institute-in-chennai–the-learnbot' element={<BlogFive />} />
            <Route path='/blog/why-choose-digital-marketing-training-in-chennai-at-the-learnbot' element={<BlogSix />} />
            <Route path="/hire-from-us" element={<HireFromUs />} />
            <Route path="/course/digital_marketing_mastery" element={<CourseTwo />} />
            <Route path="/course/digital_marketing_excellence" element={<CourseOne />} />
            <Route path="/course/seo_mastery" element={<CourseThree />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<Terms />} />
          </Route>

          {/* AdminLayout with PrivateRoute */}
          <Route path="/admin" element={<PrivateRoute><AdminLayout /></PrivateRoute>}>
            <Route index element={<Admin />} />
            <Route path="/admin/create" element={<CreateBlog />} />
            <Route path="/admin/edit/:id" element={<EditBlog />} />
          </Route>

          {/* Login Route outside of AdminLayout */}
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
