import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Admin = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://localhost:8000/api/blogs', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setBlogs(response.data);
        // console.log(response.data)
      } catch (err) {
        alert('Failed to fetch blogs');
      }
    };
    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`http://localhost:8000/api/blogs/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBlogs(blogs.filter(blog => blog.id !== id));
    } catch (err) {
      alert('Failed to delete blog');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <>
      <section className='pt-20 pb-20'>
        <div className='w-[80%] mx-auto'>
          <div className='flex justify-between items-center mb-8'>
            <h1 className='text-2xl'>List Of Blogs</h1>
            <Link to='/admin/create'>
              <button className='bg-green-500 text-white px-4 py-2 rounded'>Add Blog</button>
            </Link>
            {/* Uncomment if you want a logout button */}
            {/* <button onClick={handleLogout} className='bg-red-500 text-white px-4 py-2 rounded'>Logout</button> */}
          </div>
          
          {blogs.map(blog => (
            <div key={blog.id} className='bg-blue-400 py-8 mt-4 px-4 rounded'>
              <h2 className='text-xl'>{blog.title}</h2>
              <p>{blog.content}</p>
              {blog.image && (
                <img
                  src={`http://localhost:8000/images/${blog.image}`} // Ensure this path matches where your server serves static files
                  alt={blog.title}
                  className='w-40 object-cover h-auto mt-4 rounded'
                />
              )}
              <div className='flex justify-end mt-4'>
                <Link to={`/admin/edit/${blog.id}`}>
                  <button className='bg-yellow-500 text-white px-4 py-2 rounded mr-2'>Edit</button>
                </Link>
                <button 
                  onClick={() => handleDelete(blog.id)} 
                  className='bg-red-500 text-white px-4 py-2 rounded'>
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Admin;
