import React from 'react';

import courseBanner from '../images/course/course_banner.jpg';

import icon from '../images/icons/approved.png'


import courseOne from '../images/course/Digital_Marketing_Excellence.jpg'
import courseTwo from '../images/course/Digital_Marketing_Mastery.jpg'
import courseThree from '../images/course/seo_mastery.jpg'

import learn from '../images/course/learn.png'
import implement from '../images/course/implement.png'
import success from '../images/course/succeed.png'


import getBanner from '../images/course/get_start_banner.jpg'


import { Link } from 'react-router-dom';
import CourseFAQ from '../components/CourseFAQ';

const Course = () => {

    function topPage () {
        window.scroll(0,0)
      }

    return (
        <>
            <div className='overflow-hidden font-[montserrat]'>

                <section className='bg-teal-500 '>
                    <img src={courseBanner} className='w-full object-cover -z-20' alt='Course Banner' />
                </section>

                {/* ------------------ Highlight Points ------------------- */}
                <section className='pb-4 md:pb-10 -mt-4  md:-mt-10'>
                    <div className=' w-[90%] md:w-[85%] mx-auto '>

                        <div className='flex w-full justify-center items-center'>

                            <div className='grid grid-cols-1 md:grid-cols-3 w-full divide-y-2 md:divide-x-2 divide-slate-300 bg-[#f8f8ff] rounded-md' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">

                                <div className='p-3 md:p-4'>
                                    <div className=' md:px-4 py-2 md:py-4  flex items-center gap-x-5 md:gap-x-4' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={learn} className='w-12  md:w-16 object-cover' alt='none' />
                                        </div>
                                        <div className='flex flex-col gap-y-2'>
                                            <h3 className='font-bold text-[14px] md:text-[20px] '>Learn </h3>
                                            <p className='text-[10px] md:text-[14px] font-[roboto]'>Expert strategies for digital marketing.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-3 md:p-4'>
                                    <div className=' md:px-4  py-2 md:py-4  flex items-center gap-x-5 md:gap-x-4' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={implement} className='w-12 md:w-16 object-cover' alt='none' />
                                        </div>
                                        <div className='flex flex-col gap-y-2'>
                                            <h3 className='font-bold text-[14px] md:text-[20px]'>Implement </h3>
                                            <p className='text-[10px] md:text-[14px] font-[roboto]'>Enhance campaigns with proven techniques.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='p-3 md:p-4'>
                                    <div className=' md:px-4 py-2 md:py-4 flex items-center gap-x-5 md:gap-x-4' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={success} className='w-12 md:w-16 object-cover' alt='none' />
                                        </div>
                                        <div className='flex flex-col gap-y-2'>
                                            <h3 className='font-bold text-[14px] md:text-[20px] '>Succeed </h3>
                                            <p className='text-[10px] md:text-[14px] font-[roboto]'>Build skills to excel in digital marketing.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>



                {/* ---------------- courses ---------------- */}

                <section className='pt-6 md:pt-20 pb-10 md:pb-20'>

                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div>
                            <div className='flex flex-col justify-center gap-y-3 items-center mb-6 md:mb-16'>
                                <h3 className='text-center text-[14px] md:text-[28px] text-black font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000" > Student Output Assesment Program </h3>
                                <p className='font-[roboto] text-center text-[12px] md:text-[16px] md:tracking-[2px]  text-gray-600' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> We teach Industry standard tools through the course </p>
                            </div>
                        </div>

                        <div className='flex justify-center items-center'>
                            <div className='grid grid-cols-1 gap-y-10 md:grid-cols-3 gap-x-10 '>

                                <div data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">

                                    <div className='flex flex-col   bg-[#f8f8ff] border border-gray-300 rounded-md overflow-hidden relative w-full'>

                                        <div className='absolute top-0 right-0 w-full '>
                                            <p className='bg-[#f15b2d] px-3 py-1.5 w-fit text-white text-[10px] md:text-[12px] font-semibold '>New Course</p>
                                        </div>

                                        <div>
                                            <img src={courseOne} className='w-full object-cover rounded-t-md' alt='none' />
                                        </div>

                                        <div className='pt-5 px-3 pb-6'>

                                            <div className=''>
                                                <h2 className='font-bold text-[16px] md:text-[18px]'> Digital Marketing Excellence</h2>
                                            </div>

                                            <div className='mt-4'>
                                                <ul className='list-disc text-[12px] md:text-[14px] font-[roboto] ml-3 md:ml-6 space-y-2 marker:text-gray-800'>
                                                    <li>Foundation of Digital Marketing</li>
                                                    <li>Advanced SEO and Content Marketing</li>
                                                    <li>Social Media, Email Marketing, and PPC</li>
                                                    <li>Advanced Social Media Strategies</li>
                                                    <li>Analytics, Conversion Optimization</li>

                                                </ul>
                                            </div>

                                        </div>

                                        <div className='flex justify-center items-center'>
                                            <Link to='/course/digital_marketing_excellence' onClick={topPage} className='w-full'>
                                                <button className='bg-[#2384b3] hover:bg-[#3b7a99]  w-full text-white text-[12px] md:text-[14px] font-bold font-[poppins] py-3 border-t-2 border-[#f15b2d]'>Explore More</button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>



                                <div data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">
                                    <div className='flex flex-col   bg-[#f8f8ff] border border-gray-300 rounded-md overflow-hidden relative w-full'>

                                        <div className='absolute top-0 right-0 w-full '>
                                            <p className='bg-[#f15b2d] px-3 py-1.5 w-fit text-white text-[10px] md:text-[12px] font-semibold '>New Course</p>
                                        </div>

                                        <div>
                                            <img src={courseTwo} className='w-full object-cover rounded-t-md' alt='none' />
                                        </div>

                                        <div className='pt-5 px-3 pb-6'>

                                            <div className=''>
                                                <h2 className='font-bold text-[16px] md:text-[18px]'> Digital Marketing Mastery </h2>
                                            </div>

                                            <div className='mt-4'>
                                                <ul className='list-disc text-[12px] md:text-[14px] font-[roboto] ml-3 md:ml-6 space-y-2 marker:text-gray-800'>
                                                    <li>Website Planning and Creation</li>
                                                    <li>Content Marketing</li>
                                                    <li>Social Media Marketing</li>
                                                    <li>Email Marketing</li>
                                                    <li>Pay-Per-Click (PPC) Advertising</li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className='flex justify-center items-center'>
                                            <Link to='/course/digital_marketing_mastery' onClick={topPage} className='w-full'>
                                                <button className='bg-[#2384b3] hover:bg-[#3b7a99]  w-full text-white text-[12px] md:text-[14px] font-bold font-[poppins] py-3 border-t-2 border-[#f15b2d]'>Explore More</button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>

                                <div data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">

                                    <div className='flex flex-col   bg-[#f8f8ff] border border-gray-300 rounded-md overflow-hidden relative w-full'>

                                        <div className='absolute top-0 right-0 w-full '>
                                            <p className='bg-[#f15b2d] px-3 py-1.5 w-fit text-white text-[10px] md:text-[12px] font-semibold '>New Course</p>
                                        </div>

                                        <div>
                                            <img src={courseThree} className='w-full object-cover rounded-t-md' alt='none' />
                                        </div>

                                        <div className='pt-5 px-3 pb-6'>

                                            <div className=''>
                                                <h2 className='font-bold text-[16px] md:text-[18px]'> SEO Mastery </h2>
                                            </div>

                                            <div className='mt-4'>
                                                <ul className='list-disc text-[12px] md:text-[14px] font-[roboto] ml-3 md:ml-6 space-y-2 marker:text-gray-800'>
                                                    <li>Keyword research </li>
                                                    <li>on-page optimization </li>
                                                    <li>link building</li>
                                                    <li>technical SEO </li>
                                                    <li>SEO tools</li>


                                                </ul>
                                            </div>

                                        </div>

                                        <div className='flex justify-center items-center'>
                                            <Link to='/course/seo_mastery' onClick={topPage} className='w-full'>
                                                <button className='bg-[#2384b3] hover:bg-[#3b7a99]  w-full text-white text-[12px] md:text-[14px] font-bold font-[poppins] py-3 border-t-2 border-[#f15b2d]'>Explore Programs</button>
                                            </Link>
                                        </div>

                                    </div>

                                </div>



                            </div>


                        </div>

                    </div>

                </section>


                {/* ----------------- small banner ---------------  */}

                <section className='pt-2 md:pt-10  pb-10 md:pb-20'>
                    <div className=' w-full px-4 md:w-[80%] mx-auto'>

                        <div className='bg-teal-500 w-full rounded-md relative' data-aos='zoom-in' data-aos-delay="50" data-aos-duration="2000">

                            <img src={getBanner} className='w-full object-cover rounded-md' alt='none' />

                            <div className='absolute bottom-1.5 md:bottom-5  left-14 md:left-64 '>

                                <div>
                                    <button className='bg-[#01A2F1] hover:scale-105 duration-500 border border-gray-50 drop-shadow-lg shadow-md text-gray-50 font-bold px-3 md:px-14  py-0.5 md:py-3  text-[8px] md:text-[14px] rounded-sm md:rounded-md '>Get Start</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

                {/* -------------------- FAQ --------------------- */}

                <section className='pt-10 pb-8 md:pb-20 bg-[#f0f8ff]'>
                    <div className='px-4 w-full md:w-[80%] mx-auto'>

                        <div className='flex flex-col justify-center items-center mb-8 gap-y-3'>
                            <h3 className='text-[18px] md:text-[28px] text-center font-bold'>Frequently Asked Questions </h3>
                        </div>


                        <div className='w-full'>
                            <CourseFAQ />
                        </div>

                    </div>
                </section>




            </div>
        </>
    );
}

export default Course;
