import React from 'react'

import banner from '../images/blog/blog.jpg'

import blogOne from '../images/blog/blog_1.webp'
import blogTwo from '../images/blog/blog_2.webp'
import blogThree from '../images/blog/blog_3.webp'
import blogFour from '../images/blog/blog_4.webp'
import blogFive from '../images/blog/blog_5.png'
import blogSix from '../images/blog/blog_6.png'
import { Link } from 'react-router-dom'


const DummyBlog = () => {

    function topPage() {
        window.scroll(0.0)
    }

    return (

        <>

            <div>
                <div>
                    <img src={banner} className='w-full object-cover' />
                </div>

                <div className=' md:pt-20 pb-10 md:pb-20'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex justify-center items-center'>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-6 md:gap-y-14 gap-x-10 mt-10 md:mt-16 max-md:max-w-lg mx-auto">

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogOne} alt="Blog Post 1" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">

                                        <h3 class="text-[14px] md:text-[16px] font-bold text-[#333]">How Social Media Marketing Training at The Learnbot Can Elevate Your Career</h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600 text-[10px] md:text-[12px] truncate-3-lines">Social Media Marketing (SMM) has become one of the most powerful tools for businesses to engage with their audience, build brand awareness, and drive sales....</p>

                                            <div>
                                                <Link to='/blog/how-social-media-marketing-training-at-the-learnbot-can-elevate-your-career' onClick={topPage}>
                                                    <button className='text-blue-600 text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogTwo} alt="Blog Post 2" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">
                                        <h3 class="text-[14px] md:text-[16px] font-bold text-[#333]">Learn SEO from the Best Digital Marketing Training Institute in Chennai </h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600 text-[10px] md:text-[12px] truncate-3-lines">Search Engine Optimization (SEO) is one of the most crucial components of digital marketing today. If you’re looking to build a solid foundation in SEO, joining the best Digital Marketing Training Institute in Chennai—The Learnbot—can set you on the right path. Whether you're a student, entrepreneur, or marketing professional, learning SEO can open doors to lucrative opportunities.</p>
                                            <div>
                                                <Link to='/blog/learn-seo-from-the-best-digital-marketing-training-institute-in-chennai-the-learnbot' onClick={topPage}>
                                                    <button className='text-blue-600  text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogThree} alt="Blog Post 3" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">
                                        <h3 class="text-[14px] md:text-[16px] font-bold text-[#333]">Master Digital Marketing with Online Training from The Learnbot</h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600 text-[10px] md:text-[12px] truncate-3-lines">In today’s increasingly connected world, the demand for digital marketers has never been higher. Whether you’re looking to switch careers, boost your current business, or start something new, getting the right skills is crucial...</p>

                                            <div>
                                                <Link to='/blog/master-digital-marketing-with-online-training-from-the-learnbot' onClick={topPage}>
                                                    <button className='text-blue-600 text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogFour} alt="Blog Post 3" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">
                                        <h3 class=" text-[14px] md:text-[16px] font-bold text-[#333]">Master Social Media Marketing at the Best Digital Marketing Training Institute in Chennai – The Learnbot</h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600  text-[12px] md:text-[12px] truncate-3-lines">In today’s digital age, social media platforms are powerful tools for building brand awareness, engaging customers, and driving sales..</p>
                                            <div>
                                                <Link to='/blog/master-social-media-marketing-at-the-best-digital-marketing-training-institute-in-chennai–the-learnbot' onClick={topPage}>
                                                    <button className='text-blue-600 text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogFive} alt="Blog Post 3" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">

                                        <h3 class="text-[14px] md:text-[16px] font-bold text-[#333]">Learn Google Ads at The Best Digital Marketing Training Institute in Chennai – The Learnbot</h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600 text-[10px] md:text-[12px] truncate-3-lines">In today’s digital landscape, Google Ads has become one of the most powerful tools for businesses to reach their target audience...</p>
                                            <div>
                                                <Link to='/blog/learn-google-ads-at-the-best-digital-marketing-training-institute-in-chennai–the-learnbot' onClick={topPage}>
                                                    <button className='text-blue-600 text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="bg-white cursor-pointer rounded overflow-hidden shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] relative group">
                                    <img src={blogSix} alt="Blog Post 3" class="w-full h-96 object-cover" />
                                    <div class="p-6 absolute bottom-0 left-0 right-0 bg-white opacity-90">

                                        <h3 class="text-[14px] md:text-[16px] font-bold text-[#333]">Why Choose Digital Marketing Training in Chennai at The Learnbot?</h3>
                                        <div class="h-0 overflow-hidden group-hover:h-20 group-hover:mt-2 transition-all duration-300">
                                            <p class="text-gray-600 text-[12px] md:text-[12px] truncate-3-lines">In today’s digital era, businesses are continuously shifting their marketing strategies to online platforms, making Digital Marketing Training in Chennai one of the most sought-after skills...</p>
                                            <div>
                                                <Link to='/blog/why-choose-digital-marketing-training-in-chennai-at-the-learnbot' onClick={topPage}>
                                                    <button className='text-blue-600 text-[12px] md:text-[14px] font-semibold mt-1'>Explore More</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}

export default DummyBlog
