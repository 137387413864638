import React from 'react'

import contactBanner from '../images/contact_ban.png'

import contactanimation from '../animation/animation_contact.json'

import Lottie from "lottie-react";

//icons
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";


import contact from '../images/phone.png'
import Enquiry from '../components/Enquiry';

const Contact = () => {
    return (
        <>

            <div>

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 font-[poppins] bg-gradient-to-t from-transparent to-gray-200 relative'>

                    <div >
                        <div className='absolute  -top-8  md:-top-20  -right-6 md:-left-28 size-20 md:size-48 bg-sky-700  rounded-full'></div>
                    </div>

                    <div className='w-full px-4 md:w-[80%]  mx-auto'>

                        <div className='flex flex-col md:flex-row gap-y-4
                         items-center '>

                            <div className='w-full md:w-[50%] mx-auto'>

                                <div>
                                    <h3 className='text-[22px] md:text-[50px] font-bold '>Have Questions? </h3>
                                    <h3 className='text-[28px] md:text-[60px] font-bold text-[#f15b2d] '>We're Here to Help! </h3>
                                    <p className='font-semibold mt-4 md:mt-14 text-gray-600 text-[12px] md:text-[16px]'>Whether you have a question about our courses, pricing, or anything else, our team is ready to answer all your questions.</p>
                                </div>

                            </div>

                            <div className='w-full md:w-[50%] mx-auto'>

                                <div>
                                    {/* <img src={contactBanner} className='w-full object-cover' alt='none' /> */}

                                    <Lottie animationData={contactanimation} loop={true} className='w-full md:w-[120%] drop-shadow-md object-cover' />

                                </div>


                            </div>

                        </div>


                    </div>

                </section>


                {/* ---------------------- form section ---------------------- */}


                <section className='bg-[#f8f8ff] pt-8 md:pt-20 pb-8 md:pb-20 font-[poppins]'>

                    <div className='relative  w-full px-4 md:w-[80%] mx-auto bg-gray-900 py-8 md:px-6 rounded-lg z-0'>

                        <div className='absolute bottom-0 left-0 -z-10' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">

                            <img src={contact} alt="" />

                        </div>

                        <div className='flex flex-col md:flex-row items-start md:divide-x divide-blue-200 gap-y-4'>

                            <div className=' w-full md:w-[50%] mx-auto md:mt-20'>

                                <div className='md:px-10'>

                                    <div>
                                        <h4 className='font-semibold text-[16px] md:text-[24px]  text-white text-center'>For any inquiries or to learn more about our courses, please reach out to us</h4>
                                        <p className='text-center mt-4 md:mt-14 text-gray-100 text-[10px] md:text-[12px]'>Ready to move forward? Contact us today to get started on your journey. Our team is here to provide the information and support you need.</p>
                                    </div>

                                    <div className='mt-6 md:mt-10 '>
                                        <div className='flex justify-center md:justify-start items-center gap-x-4'>
                                            <div className='flex items-center gap-x-2'>
                                                <FaPhone className='text-white text-[14px] md:text-[16px]' />
                                                <p className='text-white text-[12px] md:text-[14px]'>+91 98765 43210</p>
                                            </div>

                                            <div className='flex items-center gap-x-2'>
                                                <MdEmail className='text-white text-[14px] md:text-[16px]' />
                                                <p className='text-white text-[12px] md:text-[14px]'>contact@thelearnbot.com</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div className=' w-full md:w-[50%] mx-auto'>

                               <Enquiry />

                            </div>

                        </div>
                    </div>
                </section>


                {/* ------------------------------ map location ----------------------------- */}

                {/* <section className='pt-10 md:pt-20 pb-10 md:p-20'>
                    <div className='w-full px-4 md:w-[90%] mx-auto'>

                        <div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1189.4247650050131!2d80.21828340473999!3d13.045936289999364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526766a8de558b%3A0x76fcc2651e0e84fa!2sCinema%20Factory%20Entertainment!5e0!3m2!1sen!2sin!4v1725613508953!5m2!1sen!2sin" 
                            className='w-full  h-[14rem] md:h-[26rem] border-none focus:border-none rounded-md' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>
                </section> */}

            </div>

        </>
    )
}

export default Contact