import React from 'react'

const CourseForm = () => {
    return (
        <>

            <div>
                <section>
                    <div className="flex items-center justify-center py-8 md:py-12 md:px-24" data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">
                        <div className="mx-auto w-full max-w-[550px] bg-white">
                            <form>
                                <div className="mb-5">
                                    <label htmlFor="name" className="mb-3 block  text-[14px] md:text-[16px] font-medium text-[#07074D]">
                                        Full Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Full Name"
                                        className=" text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    />
                                </div>

                                <div className="flex flex-col md:flex-row items-center gap-x-2 w-full">
                                    <div className="mb-5 w-full">
                                        <label htmlFor="email" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">
                                            Company Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Enter your email"
                                            className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                        />
                                    </div>

                                    <div className="mb-5 w-full">
                                        <label htmlFor="phone" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">
                                            Mobile Number
                                        </label>
                                        <input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            placeholder="Enter your Mobile Number"
                                            className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                        />
                                    </div>
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="designation" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">
                                        Designation
                                    </label>
                                    <input
                                        type="text"
                                        name="designation"
                                        id="designation"
                                        placeholder="Enter your Designation"
                                        className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    />
                                </div>

                                <div className="mb-5">
                                    <label htmlFor="domains" className="mb-3 block text-[14px] md:text-[16px] font-medium text-[#07074D]">
                                        Domains you want to hire
                                    </label>
                                    <select
                                        name="domains"
                                        id="domains"
                                        className="text-[12px] md:text-[14px] w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6  text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    >
                                        <option>Domains you want to hire</option>
                                        <option>Digital Marketing</option>
                                        <option>Software Development</option>
                                        <option>Graphic Design</option>
                                        <option>Content Writing</option>
                                    </select>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="uppercase hover:shadow-form w-full rounded-md border border-gray-50 hover:border-orange-500 hover:text-gray-950 duration-500 delay-200 hover:bg-transparent bg-[#f15b2d] py-3 px-8 text-center  text-[14px] md:text-[16px] font-semibold text-white outline-none"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default CourseForm