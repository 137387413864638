import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [currentImage, setCurrentImage] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`http://localhost:8000/api/blogs/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTitle(response.data.title);
        setContent(response.data.content);
        setCurrentImage(response.data.image); // Assuming 'image' is the field name
      } catch (err) {
        alert('Failed to fetch blog details');
      }
    };
    fetchBlog();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (image) {
      formData.append('image', image);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put(`http://localhost:8000/api/blogs/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate('/admin');
    } catch (err) {
      alert('Failed to update blog');
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <form onSubmit={handleSubmit} className='bg-white p-8 rounded shadow-md'>
        <h2 className='text-2xl mb-4'>Edit Blog</h2>
        <input 
          type='text' 
          placeholder='Title' 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          className='mb-4 p-2 border w-full'
        />
        <textarea 
          placeholder='Content' 
          value={content} 
          onChange={(e) => setContent(e.target.value)} 
          className='mb-4 p-2 border w-full h-40'
        />
        {currentImage && (
          <div className='mb-4'>
            <img src={`http://localhost:8000/images/${currentImage}`} alt='Current' className='w-32 h-32 object-cover' />
          </div>
        )}
        <input 
          type='file' 
          onChange={(e) => setImage(e.target.files[0])} 
          className='mb-4'
        />
        <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
          Update
        </button>
      </form>
    </div>
  );
};

export default EditBlog;
