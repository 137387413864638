import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (image) {
      formData.append('image', image);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post('http://localhost:8000/api/blogs', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate('/admin');
    } catch (err) {
      alert('Failed to create blog');
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <form onSubmit={handleSubmit} className='bg-white p-8 rounded shadow-md'>
        <h2 className='text-2xl mb-4'>Create New Blog</h2>
        <input 
          type='text' 
          placeholder='Title' 
          value={title} 
          onChange={(e) => setTitle(e.target.value)} 
          className='mb-4 p-2 border w-full'
        />
        <textarea 
          placeholder='Content' 
          value={content} 
          onChange={(e) => setContent(e.target.value)} 
          className='mb-4 p-2 border w-full h-40'
        />
        <input 
          type='file' 
          onChange={(e) => setImage(e.target.files[0])} 
          className='mb-4'
        />
        <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateBlog;
