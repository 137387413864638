import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import AOS from 'aos'
import 'aos/dist/aos.css'

import banner from '../images/banner/1.jpg'
import dmm from '../images/DMM.jpg'
import dme from '../images/DME.jpg'
import seo from '../images/SEO.jpg'

import bannerOne from '../images/banner-1.png'
import img from '../images/img.png'
import contact from '../images/phone.png'


import toolOne from '../images/tools/google-analytics.png'
import toolTwo from '../images/tools/semrush.png'
import toolThree from '../images/tools/Hootsuite.png'
import toolFour from '../images/tools/google_ads.png'
import toolFive from '../images/tools/facebook.png'
import toolSix from '../images/tools/mailchimp.png'
import toolSeven from '../images/tools/Ahrefs.png'
import toolEight from '../images/tools/moz.png'
import toolNine from '../images/tools/hubspot.png'
import toolTen from '../images/tools/canva.png'
import toolElven from '../images/tools/buffer.png'
import toolTwelve from '../images/tools/trello.png'
import toolThirteen from '../images/tools/wordpress.png'
import toolFourteen from '../images/tools/Yoast.png'
import toolFifteen from '../images/tools/google_search.png'
import toolSixteen from '../images/tools/hotjar.png'
import toolSeventeen from '../images/tools/slack.png'
import toolEighteen from '../images/tools/zapier.png'
import toolNineteen from '../images/tools/adobe.png'
import toolTwenty from '../images/tools/excel.png'


// icons
import { FaArrowRight } from "react-icons/fa6";
import { IoPlay } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";




import iconOne from '../images/icons/1.png'
import iconTwo from '../images/icons/2.png'
import iconThree from '../images/icons/3.png'
import iconFour from '../images/icons/4.png'
import iconFive from '../images/icons/5.png'
import iconSix from '../images/icons/6.png'
import iconSeven from '../images/icons/7.png'
import iconEight from '../images/icons/9.png'


import Faq from '../components/FAQ';
import Enquiry from '../components/Enquiry';



const Home = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 })
    }, [])


    function topPage() {
        window.scroll(0, 0)
    }

    return (
        <div className='overflow-hidden'>

            <div className='fixed top-0 -z-20 w-full h-screen'>
                <img src={banner} className='w-full object-cover' />
            </div>

           

            {/* --------------------- first section ------------------------- */}

            <section className='pt-12  pb-6 md:pb-10 bg-gradient-to-t from-transparent to-gray-200 font-[montserrat] '>

                <div className='w-full px-4 md:w-[80%] mx-auto'>

                    <div className='flex flex-col gap-y-6 md:gap-y-14 md:flex-row items-center'>

                        <div className='w-full md:w-[50%]'>

                            <div>
                                <p className='text-[#f15b2d] text-[16px] md:text-[18px] font-bold  md:mb-2' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">Excel with</p>
                                <h2 className='text-[#333333] font-bold text-[32px] md:text-[55px]  font-[poppins] uppercase' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000"> Our Unique  </h2>
                                <h2 className='text-[#333333] font-bold text-[32px] md:text-[55px]  font-[poppins] uppercase' data-aos='fade-right' data-aos-delay="80" data-aos-duration="3000"> Trending Courses! </h2>

                                <p className='mt-3 md:mt-12 text-[14px] md:text-[20px] font-semibold text-gray-600' data-aos='fade-right' data-aos-delay="100" data-aos-duration="3000">Master the Hottest Digital Marketing Skills and Lead the Future of Online Success.</p>
                            </div>


                            <div className='mt-8 md:mt-10 flex items-center gap-x-6 md:gap-x-14' data-aos='fade-right' data-aos-delay="50" data-aos-duration="3000">
                                <div>
                                    <button className='font-semibold text-[12px] md:text-[16px] px-4 md:px-8 py-2 md:py-4 rounded-md md:rounded-xl bg-[#2384b3] hover:scale-105 duration-300 text-white  flex items-center gap-x-2 md:gap-x-3'> Get Started <span><FaArrowRight className='text-white font-bold text-[12px] md:text-[15px]' /></span> </button>
                                </div>
                                <div className='flex items-center gap-x-4 md:gap-x-6'>
                                    <div className=' size-10 md:size-12 bg-[#f15b2d] shadow-md shadow-orange-500 drop-shadow-lg rounded-full flex justify-center items-center '>
                                        <IoPlay className='text-white text-[18px] md:text-[24px]' />
                                    </div>
                                    <div>
                                        <p className='underline  md:underline-offset-2 text-[14px] md:text-[16px] font-semibold text-gray-600'>Play Video</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full md:w-[50%] mx-auto' data-aos='fade-left' data-aos-delay="50" data-aos-duration="1000">

                            <div className='flex justify-center md:justify-end'>

                                <img src={img} className=' w-full md:w-[90%] object-cover' alt='none' />

                            </div>

                        </div>

                    </div>

                </div>

            </section>

            {/* ------------------------------- Tools covered --------------------------- */}


            <section className='pt-8 md:pt-20 pb-8 md:pb-20  bg-[#fafdff]'>

                <div className='w-full px-4 md:w-[80%] mx-auto'> 

                    <div className='w-full'>

                        <div>
                            <div className='flex flex-col justify-center gap-y-3 items-center mb-8 md:mb-16'>
                                <h3 className=' text-[18px] md:text-[28px] text-black font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000" > Tools Covered </h3>
                                <p className='font-[roboto] text-center text-[12px] md:text-[16px] md:tracking-[2px]  text-gray-600' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> Learn to harness powerful digital marketing tools to drive success and optimize your strategies </p>
                            </div>
                        </div>


                        <div className='flex justify-center items-center font-[poppins]'>

                            <div className='grid grid-cols-3  md:grid-cols-5 gap-x-2 sm:gap-x-5 md:gap-x-20  gap-y-6 md:gap-y-20'>

                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolOne} className=' w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Google Analytics</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolTwo} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>SEMrush</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolThree} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Hootsuite</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolFour} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Google Ads</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolFive} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Facebook Ads </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolSix} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Mailchimp</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolSeven} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Ahrefs</p>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                        <div>
                                            <img src={toolEight} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Moz</p>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolNine} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>HubSpot</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolTen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Canva</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolElven} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Buffer</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolTwelve} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Trello</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                        <div>
                                            <img src={toolThirteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>WordPress</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolFourteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Yoast SEO</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolFifteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Search Console</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolSixteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Hotjar</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolSeventeen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Slack</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                        <div>
                                            <img src={toolEighteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Zapier</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                        <div>
                                            <img src={toolNineteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Adobe Creative Suite</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                        <div>
                                            <img src={toolTwenty} className='w-12 md:w-20 object-cover ' alt='none' />
                                        </div>
                                        <div>
                                            <p className='text-center text-[10px] md:text-[14px] font-semibold '>Microsoft Excel</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>




            {/* ----------------- Courses section  --------------------------- */}

            <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#f8f8ff]'>

                <div className='w-full px-4 md:w-[80%] mx-auto'>


                    <div className='  w-full '>
                        <div className=' flex justify-center items-center w-full'>
                            <div className='w-full'>

                                <div>
                                    <div className='flex flex-col justify-center gap-y-3 items-center mb-8 md:mb-16'>
                                        <h3 className=' text-[18px] md:text-[28px] text-black font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000" >Trending Course's</h3>
                                        <p className='font-[roboto] text-center text-[12px] md:text-[16px] md:tracking-[2px]  text-gray-600' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000">Unlock the latest digital marketing trends with our courses and stay at the forefront of innovation and strategy.</p>
                                    </div>
                                </div>

                                <div className='flex flex-col md:flex-row items-center gap-y-10 justify-center w-full'>

                                    <div className='bg-white border-2 border-gray-400  rounded-md md:rounded-l-lg  w-[20rem] h-[23rem] -mr-2  z-10' data-aos='fade-right' data-aos-delay="50" data-aos-duration="2000">
                                        <div>

                                            <div>
                                                <img src={dmm} className='object-cover w-full rounded-md md:rounded-t-lg' alt="" />
                                            </div>

                                            <div className='px-4 mt-4'>
                                                <h3 className='font-bold  text-[16px] mb-2 md:mb-6 text-center uppercase'>Digital Marketing Mastery</h3>

                                                {/* <p className='text-[16px] mb-2 text-center font-[roboto]'>₹ 12,000</p> */}

                                                <div className='text-[14px] font-[roboto] flex flex-col gap-y-1'>
                                                    <p className='text-center'> <span className='font-medium'>Includes:</span> Course certificate </p>
                                                    <p className='text-center'> <span className='font-medium'>Duration:</span> 2 Months</p>
                                                </div>

                                                <div className='flex justify-center mt-5'>
                                                    <Link to='/course/digital_marketing_mastery' onClick={topPage} className='w-full flex justify-center'>
                                                        <button className='font-semibold uppercase text-[12px] px-4 py-1.5 rounded-md border border-teal-600 bg-[#2384b3] font-[poppins] text-white '>Learn More</button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='bg-white border-2 border-gray-400  rounded-md md:rounded-lg  w-[20rem] h-[23rem] md:h-[26rem] z-20 ' data-aos='fade-up' data-aos-delay="80" data-aos-duration="3000">

                                        <div>

                                            <div>
                                                <img src={dme} className='object-cover w-full rounded-md md:rounded-t-lg' alt="" />
                                            </div>

                                            <div className='px-4 mt-4'>

                                                <h3 className='font-bold text-[16px] mb-2 md:mb-6 text-center uppercase'>Digital Marketing Excellence</h3>

                                                {/* <p className='text-[16px] mb-2 text-center font-[roboto]'>₹ 20,000</p> */}

                                                <div className='text-[14px] font-[roboto] flex flex-col gap-y-1'>
                                                    <p className='text-center'><span className='font-medium'>Includes:</span> Course certificate </p>
                                                    <p className='text-center'><span className='font-medium'>Duration:</span> 4 months </p>
                                                </div>

                                                <div className='flex justify-center mt-8'>
                                                    <Link to='/course/digital_marketing_excellence' onClick={topPage} className='w-full flex justify-center'>
                                                        <button className='font-semibold uppercase text-[12px] px-4 py-1.5 rounded-md border border-teal-600  bg-[#2384b3] font-[poppins] text-white '>Learn More</button>
                                                    </Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    <div className='bg-white border-2 border-gray-400  rounded-md md:rounded-r-lg  w-[20rem] h-[23rem] -ml-2 z-10 ' data-aos='fade-left' data-aos-delay="50" data-aos-duration="2000">

                                        <div>

                                            <div>
                                                <img src={seo} className='object-cover w-full rounded-md md:rounded-t-lg' alt="" />
                                            </div>

                                            <div className='px-4 mt-4'>

                                                <h3 className='font-bold text-[16px] mb-2 md:mb-6 text-center uppercase'>SEO Mastery</h3>

                                                {/* <p className='text-[16px] mb-2 text-center font-[roboto]'>₹ 00,000</p> */}

                                                <div className='text-[14px] font-[roboto] flex flex-col gap-y-1'>
                                                    <p className='text-center'><span className='font-medium'>Includes:</span> Course Certificate </p>
                                                    <p className='text-center'><span className='font-medium'>Duration:</span> 1 Month</p>
                                                </div>

                                                <div className='flex justify-center mt-5'>
                                                    <Link to='/course/seo_mastery' onClick={topPage} className='w-full flex justify-center '>
                                                        <button className='font-semibold uppercase text-[12px] px-4 py-1.5 border border-teal-600 rounded-md bg-[#2384b3] font-[poppins] text-white '>Learn More</button>
                                                    </Link>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className='flex justify-center items-center  mt-8 md:mt-12' data-aos='fade-up' data-aos-delay="50" data-aos-duration="3000">
                                    <Link to='/course' onClick={topPage} className='w-full flex justify-center items-center'>
                                        <button className='bg-[#f15b2d] px-5 md:px-8 py-2 rounded-md font-semibold md:font-bold font-[poppins] text-white text-[12px] md:text-[14px] '>View All Courses</button>
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>


            {/* ---------------------------- OUR EXTENSIVE OFFERINGS ------------------ */}

            <section className='pt-8 md:pt-20 pb-8 md:pb-20 bg-[#f0f8ff] backdrop-blur-sm '>
                <div className='w-full px-4 md:w-[80%] mx-auto'>

                    <div>
                        <div className='flex flex-col justify-center gap-y-3 items-center mb-8 md:mb-16'>
                            <h1 className=' text-[18px] md:text-[28px] text-black font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000" >OUR EXTENSIVE OFFERINGS</h1>
                            <p className='font-[roboto] text-center text-[12px] md:text-[16px] md:tracking-[2px]  text-gray-600  w-full md:w-[80%]' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> Explore our extensive offerings to find comprehensive courses that cover every facet of digital marketing and empower your success. </p>
                        </div>
                    </div>

                    <div className='flex justify-center items-center font-[poppins]'>

                        <div className='grid grid-cols-2 md:grid-cols-4 gap-x-6 md:gap-x-20 gap-y-10 md:gap-y-20'>

                            <div  >
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconOne} className=' w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Comprehensive Curriculum</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconTwo} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Industry-Experienced Instructors</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconThree} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Certification</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconFour} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Practical, Hands-On Training</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconFive} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Latest Tools and Techniques</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconSix} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Personalized Mentorship</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconSeven} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Flexible Learning Options</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                    <div>
                                        <img src={iconEight} className='w-16 md:w-20 object-cover ' alt='none' />
                                    </div>
                                    <div>
                                        <p className='text-center text-[12px] md:text-[14px] font-semibold '>Lifetime Access to Resources</p>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>
            </section>



            {/* ------------------------------ faq  -------------------- */}

            <section className='pt-8 md:pt-20 pb-8 md:pb-20  bg-white'>
                <div className='w-full px-4  md:w-[80%] mx-auto flex flex-col md:flex-row items-center justify-between'>

                    <div className=' w-full flex flex-col gap-y-1 md:gap-y-4  mx-auto' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">

                        <div>
                            <h3 className='font-bold text-center text-[20px]  md:text-[28px] uppercase mb-4 md:mb-10'>Frequently Asked Questions</h3>

                            <p className='text-center text-[12px] md:text-[14px] text-gray-800 mb-5 md:mb-6'>Find all the answers you need in our detailed FAQ section, crafted to clear up any doubts and guide you through our course offerings and services.</p>

                            {/* <div className='flex justify-center '>
                                <button className='bg-gray-900 border border-gray-500 rounded-lg px-6 py-2 text-white text-[14px] md:text-[16px]'>View All Questions</button>
                            </div> */}
                        </div>

                        <div className=' w-full  mx-auto'>

                            <div>
                                <Faq />
                            </div>

                        </div>

                    </div>
                </div>
            </section>

            {/* ---------------------- contact -------------------- */}

            <section className='bg-[#f8f8ff] pt-0 md:pt-20 pb-8 md:pb-20 font-[poppins]'>

                <div className='relative  w-full px-4 md:w-[80%] mx-auto bg-gray-900 py-8 md:px-6 rounded-lg z-0'>

                    <div className='absolute bottom-0 left-0 -z-10' data-aos='fade-right' data-aos-delay="50" data-aos-duration="1000">

                        <img src={contact} alt="" />

                    </div>

                    <div className='flex flex-col md:flex-row items-start md:divide-x divide-blue-200 gap-y-4'>

                        <div className=' w-full md:w-[50%] mx-auto md:mt-20'>

                            <div className='md:px-10'>

                                <div>
                                    <h4 className='font-semibold text-[16px] md:text-[24px]  text-white text-center'> For any inquiries or to learn more about our courses, please reach out to us </h4>
                                    <p className='text-center mt-4 md:mt-14 text-gray-100 text-[10px] md:text-[12px]'>  Don't miss out—secure your future by mastering the skills that define tomorrow's market!</p>
                                </div>

                                <div className='mt-6 md:mt-10 '>
                                    <div className='flex justify-center md:justify-start items-center gap-x-4'>
                                        <div className='flex items-center gap-x-2'>
                                            <FaPhone className='text-white text-[14px] md:text-[16px]' />
                                            <p className='text-white text-[12px] md:text-[14px]'>+91 98765 43210</p>
                                        </div>

                                        <div className='flex items-center gap-x-2'>
                                            <MdEmail className='text-white text-[14px] md:text-[16px]' />
                                            <p className='text-white text-[12px] md:text-[14px]'>contact@thelearnbot.com</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className=' w-full md:w-[50%] mx-auto'>

                           <Enquiry />

                        </div>

                    </div>


                </div>
            </section>


        </div>
    )
}

export default Home
