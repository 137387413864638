import React from 'react'

import CourseOneSyllabus from '../components/CourseOneSyllabus';

import { MdOutlinePhoneAndroid } from "react-icons/md";
import CourseForm from '../components/CourseForm';

import banner from '../images/banner/DGB2.jpg'

import why from '../images/why.jpg'

import toolOne from '../images/tools/google-analytics.png'
import toolTwo from '../images/tools/semrush.png'
import toolThree from '../images/tools/Hootsuite.png'
import toolFour from '../images/tools/google_ads.png'
import toolFive from '../images/tools/facebook.png'
import toolSix from '../images/tools/mailchimp.png'
import toolSeven from '../images/tools/Ahrefs.png'
import toolEight from '../images/tools/moz.png'
import toolNine from '../images/tools/hubspot.png'
import toolTen from '../images/tools/canva.png'
import toolElven from '../images/tools/buffer.png'
import toolTwelve from '../images/tools/trello.png'
import toolThirteen from '../images/tools/wordpress.png'
import toolFourteen from '../images/tools/Yoast.png'
import toolFifteen from '../images/tools/google_search.png'
import toolSixteen from '../images/tools/hotjar.png'
import toolSeventeen from '../images/tools/slack.png'
import toolEighteen from '../images/tools/zapier.png'
import toolNineteen from '../images/tools/adobe.png'
import toolTwenty from '../images/tools/excel.png'

import certificate from '../images/certificate.jpg'
import getBanner from '../images/banner/get_banner.png'
import Faq from '../components/FAQ';
import CourseOneFAQ from '../components/CourseOneFAQ';



const CourseTwo = () => {
    return (
        <>

            <div className='font-[poppins] overflow-hidden'>

                <div className='fixed top-0 w-full h-screen -z-20'>
                    <img src={banner} className='w-full object-cover' />
                </div>

                <section>
                    <div className='pt-0 md:pt-10  md:pb-10 '>
                        <div className='w-full px-4 md:w-[90%] mx-auto bg-white rounded-lg border border-gray-300'>

                            <div className='flex flex-col md:flex-row gap-y-2 md:gap-y-12 items-start '>

                                <div className='w-full md:w-[50%] mx-auto'>

                                    <div className=' md:px-14 py-4 md:py-12'>
                                        <div>
                                            <h3 className='font-semibold mb-4 md:mb-8 mt-4 text-[20px] md:text-[32px]'>Digital Marketing Excellence</h3>

                                            <p className='text-[12px] md:text-[14px] text-gray-700'>
                                                This 4-month course delivers in-depth training in SEO, content marketing, social media, and analytics. Ideal for students,
                                                entrepreneurs, and professionals, it combines practical projects with expert instruction for digital marketing success.
                                            </p>

                                            <div className='mt-2 md:mt-5'>
                                                <p className='font-semibold text-[14px] text-[#f15b2d]'>4 month Course  </p>
                                            </div>

                                            <ul className='font-semibold text-[14px] md:text-[16px] list-disc ml-4 space-y-2  mt-4 md:mt-8 '>

                                                <li>Real-World Projects</li>
                                                <li>Get Certified</li>
                                                <li>Expert Industry Mentorship</li>
                                                <li>Immersive Learning Experience</li>
                                                <li>Launch Your Future</li>

                                            </ul>

                                            <div className='mt-6 md:mt-10'>
                                                <a href="tel:+919884683888" target="_blank" rel="noopener noreferrer" className="">

                                                    <button className='bg-[#2384b3] text-white shadow-md drop-shadow-md h-8 md:h-10 pl-3 rounded-md flex items-center gap-x-3  font-[poppins]  text-[12px] md:text-[16px] hover:scale-105 duration-500'> Ready to kickstart your career <div className='px-3 bg-gray-950  rounded-r-md'><MdOutlinePhoneAndroid className='text-white h-8 md:h-10  text-[18px]  md:text-[22px] font-bold' /> </div>  </button>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='w-full md:w-[50%] mx-auto'>

                                    <div>
                                        <CourseForm />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </section>


                {/*---------------------- Why choose Digital Marketing Excellence? ------------------------ */}

                <section className=' pt-10 pb-10 md:pb-0 md:pt-0 bg-white'>
                    <div className='w-full mx-auto'>

                        <div className='flex flex-col md:flex-row justify-center items-center gap-y-8 '>

                            <div className='w-full md:w-[40%] mx-auto'>
                                <img src={why} className='w-full md:w-[90%] object-cover' />
                            </div>

                            <div className='w-full md:w-[60%] mx-auto px-4 md:px-0 md:pl-20'>

                                <div className=' mb-6 md:mb-8'>
                                    <h3 className='text-start md:text-start text-[14px] md:text-[30px]  font-bold'> Why choose Digital Marketing Excellence?  </h3>
                                </div>

                                <div className='font-[roboto]'>
                                    <ul className='list-disc font-semibold text-[11px] md:text-[14px]  space-y-3 md:space-y-6 ml-4'>
                                        <li>Comprehensive 4-Month Course for Indian students, entrepreneurs, professionals, and freelancers.</li>
                                        <li>Covers digital marketing basics, website creation, and foundational SEO.</li>
                                        <li> Focuses on advanced SEO techniques and content marketing strategies.</li>
                                        <li>Delves into social media marketing, email marketing, and PPC advertising.</li>
                                        <li>Includes web analytics, conversion rate optimization, and strategy development.</li>
                                        <li>Real-world application of skills, leading to certification.</li>
                                    </ul>
                                </div>

                                <div className='mt-6 md:mt-8'>
                                    <button className='px-8 py-2 rounded-md bg-[#f15b2d] hover:bg-orange-600 duration-500 hover:border hover:border-gray-400  font-semibold text-[12px] md:text-[14px] uppercase text-white'>Apply Now</button>
                                </div>

                            </div>


                        </div>

                    </div>
                </section>


                {/* ---------------- Tools used ----------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#f8f8ff]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='w-full'>

                            <div>
                                <div className='flex flex-col justify-center gap-y-3 items-center mb-8 md:mb-16'>
                                    <h3 className=' text-[18px] md:text-[28px] text-black font-bold uppercase' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000" > Tools Covered </h3>
                                    <p className='font-[roboto] text-center text-[12px] md:text-[16px] md:tracking-[2px]  text-gray-600' data-aos='fade-up' data-aos-delay="50" data-aos-duration="2000"> Learn to harness powerful digital marketing tools to drive success and optimize your strategies </p>
                                </div>
                            </div>

                            <div className='flex justify-center items-center font-[poppins]'>

                                <div className='grid grid-cols-3  md:grid-cols-5 gap-x-2 md:gap-x-20  gap-y-6 md:gap-y-20'>

                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolOne} className=' w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Google Analytics</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolTwo} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>SEMrush</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolThree} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Hootsuite</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolFour} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Google Ads</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolFive} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Facebook Ads </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolSix} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Mailchimp</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolSeven} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Ahrefs</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                            <div>
                                                <img src={toolEight} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Moz</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolNine} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>HubSpot</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolTen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Canva</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolElven} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Buffer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolTwelve} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Trello</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                            <div>
                                                <img src={toolThirteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>WordPress</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolFourteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Yoast SEO</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolFifteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Search Console</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolSixteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Hotjar</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-right' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolSeventeen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Slack</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up' data-aos-delay="10" data-aos-duration="1000">
                                            <div>
                                                <img src={toolEighteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Zapier</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="1000">
                                            <div>
                                                <img src={toolNineteen} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Adobe Creative Suite</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='flex flex-col items-center gap-y-3' data-aos='fade-up-left' data-aos-delay="50" data-aos-duration="2000">
                                            <div>
                                                <img src={toolTwenty} className='w-12 md:w-20 object-cover ' alt='none' />
                                            </div>
                                            <div>
                                                <p className='text-center text-[10px] md:text-[14px] font-semibold '>Microsoft Excel</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </section>


                {/* -------------------------- Courses ----------------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#f0f8ff]'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col items-center justify-center gap-y-2 '>
                            <h2 className='font-bold text-center text-[16px] md:text-[28px] text-black '>Industry-Validated Curriculum for the Latest Digital Marketing Trends</h2>
                            <p className='text-center text-[10px] md:text-[14px] text-gray-700 w-full md:w-[80%]'>
                                Discover our cutting-edge digital marketing curriculum, crafted and validated by industry leaders to ensure
                                you stay ahead of the latest trends. This course offers practical, up-to-date knowledge for mastering today's
                                digital marketing landscape
                            </p>
                        </div>

                        <div className='flex justify-center items-center mt-4 md:mt-10'>

                            <div className='w-full md:w-[90%] mx-auto '>

                                <div className='bg-[#2384b3] rounded-md flex flex-col md:flex-row justify-between items-center gap-y-4 py-4 md:py-6  px-0 md:px-10 w-full'>
                                    <h2 className='text-white  text-[16px] md:text-[20px] font-bold uppercase text-center'> Your Digital marketing Blueprint</h2>
                                    <button className='bg-[#f15b2d] px-3 md:px-4  py-1.5 md:py-2.5 rounded-lg text-[12px] md:text-[14px] font-semibold border border-gray-100/60  text-white'>Download Syllabus</button>
                                </div>

                                <div>
                                    <CourseOneSyllabus />
                                </div>

                            </div>

                        </div>

                    </div>
                </section>


                {/* ------------- certicate ------------------- */}

                <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-gray-950'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col items-center justify-center gap-y-2 '>
                            <h2 className='font-bold  text-[16px] md:text-[28px] text-white text-center'>Online Digital Marketing Course Certificate</h2>
                            <p className='text-center text-[10px] md:text-[14px] text-gray-50 w-full md:w-[80%]'>
                                Discover our cutting-edge digital marketing curriculum, crafted and validated by industry leaders to ensure
                                you stay ahead of the latest trends. This course offers practical, up-to-date knowledge for mastering today's
                                digital marketing landscape
                            </p>
                        </div>

                        <div className=' mt-8 md:mt-12'>
                            <div className='flex justify-center items-center'>
                                <img src={certificate} className=' w-[90%] md:w-[70%] rounded-md object-cover' />
                            </div>
                        </div>


                    </div>
                </section>


                {/* ----------------- small banner ---------------  */}

                {/* <section className='pt-4 md:pt-10 pb -10 md:pb-20 bg-[#fafdff]'>
                    <div className='w-full px-4  md:w-[80%] mx-auto'>

                        <div className='bg-teal-500 w-full rounded-md relative' >

                            <img src={getBanner} className='w-full object-cover rounded-md' alt='none' />

                            <div className='absolute bottom-20  left-10 '>

                                <div>
                                    <button className='bg-gray-950 text-white font-bold px-10 py-2  rounded-md '>Get Start</button>
                                </div>

                            </div>

                        </div>

                    </div>
                </section> */}


                {/* -------------------------- FAQ ------------------------- */}

                <section className='bg-[#faf9f6] pt-10 md:pt-20 pb-10 md:pb-20'>
                    <div className='w-full px-4 md:w-[80%] mx-auto'>

                        <div className='flex flex-col items-center justify-center gap-y-2 '>
                            <h2 className='font-bold text-[16px] md:text-[28px] text-black text-center'>Frequently Asked Questions</h2>
                            <p className='text-[11px] md:text-[14px] text-center text-gray-700 '>Get answers to common questions about our "Digital Marketing Excellence in 120 Days" course, including course details, mentorship, payment options, and certification.</p>

                        </div>

                        <div className='mt-6'>
                            <div>
                                <CourseOneFAQ />
                            </div>
                        </div>

                    </div>


                </section>

            </div>

        </>
    )
}

export default CourseTwo
