import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { IoVolumeHigh } from "react-icons/io5";
import { CgWebsite } from "react-icons/cg";
import { FaSearchengin } from "react-icons/fa6";
import { GrTechnology } from "react-icons/gr";

import { MdOutlineContentPaste } from "react-icons/md";
import { PiNotepadFill } from "react-icons/pi";
import { TbDeviceIpadStar } from "react-icons/tb";

import { HiOutlineSpeakerphone } from "react-icons/hi";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { TbHandClick } from "react-icons/tb";



function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const CourseThreeSyllabus = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (value) => {
    setOpen(open === value ? null : value);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-transparent  pt-5 md:pt-6  w-full  mx-auto font-[poppins]">

      <div className="grid grid-cols-1 md:grid-cols-1  gap-x-14 gap-y-3 md:gap-y-10">

      <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white border-b border-gray-400  rounded-t-md cursor-pointer" onClick={() => handleOpen(1)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              <div className="flex flex-col md:flex-row md:items-center gap-x-16 ">

                <div className="flex flex-col md:justify-center md:items-center gap-y-1">
                  <p className=" flex items-center gap-x-2 text-[12px] md:text-[16px] ">Month <span className="flex md:hidden">1</span> </p>
                  <div className="hidden md:flex size-14 rounded-full bg-teal-600  justify-center items-center">
                    <span className="font-[roboto] text-[18px] text-white font-semibold">1</span>
                  </div>
                </div>

                <div className="flex flex-col gap-y-3 md:gap-y-6">

                  <h2 className="text-[14px] md:text-[16px]">Digital Marketing Essentials</h2>

                  <div>
                    <ul className="items-center gap-x-4  grid grid-cols-2 md:grid-cols-4 gap-y-4 text-[10px] md:text-[14px]">
                      <li className="flex items-center gap-x-2 font-semibold font-[roboto]"> <span><IoVolumeHigh className="text-[20px] text-orange-500" /></span> <span >Introduction to Digital Marketing</span> </li>
                      <li className="flex items-center gap-x-2 font-semibold font-[roboto]"> <span><CgWebsite className="text-[20px] text-orange-500" /></span> <span > Website Planning and Creation</span> </li>
                      <li className="flex items-center gap-x-2 font-semibold font-[roboto]"> <span><FaSearchengin className="text-[20px] text-orange-500" /></span> <span >Search Engine Optimization (SEO)</span> </li>
                      <li className="flex items-center gap-x-2 font-semibold font-[roboto]"> <span><GrTechnology className="text-[20px] text-orange-500" /></span> <span >Content Marketing</span> </li>
                    </ul>
                  </div>

                </div>

              </div>

              <Icon id={1} open={open} />
            </div>
            {open === 1 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm md:px-3">
                <div className="text-gray-800   font-normal pt-4 pb-4">
                  <ul className="grid grid-cols-2 md:grid-cols-4 gap-x-5 gap-y-5 px-2  text-[10px] md:text-[14px] font-medium">
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Overview of Digital Marketing</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Importance in Today's World</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Key Concepts and Terminology</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Understanding Websites </li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Domain and Hosting</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Basics of HTML and CSS</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Creating a WordPress Site</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">What is SEO?</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">On-Page SEO</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Keyword Research</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Off-Page SEO</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Backlinking Strategies</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Social Bookmarking</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Technical SEO</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Site Speed</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Mobile Friendliness</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Content Marketing?</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Creating a Content Strategy</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Content Calendar Planning</li>
                    <li className="text-center bg-blue-600/25 px-3 py-1.5 rounded-md">Content Promotion Strategies</li>

                  </ul>

                </div>
              </div>
            )}
          </div>
        </div>





      </div>
    </div>
  );
};

export default CourseThreeSyllabus;
