import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const CourseOneFAQ = () => {
    const [open, setOpen] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? null : value);
    };

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="bg-transparent  md:pt-6  md:px-6 w-full md:w-[95%] mx-auto font-[poppins]">

            <div className="grid grid-cols-1 md:grid-cols-2  gap-x-14 gap-y-3 md:gap-y-10">

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(1)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What is the focus of the "Digital Marketing Excellence in 120 Days" course?
                            <Icon id={1} open={open} />
                        </div>
                        {open === 1 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800  text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    This course is designed to provide a deep understanding of digital marketing, including SEO, content marketing, social media, PPC, and more.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(2)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How does this course differ from other digital marketing courses?
                            <Icon id={2} open={open} />
                        </div>
                        {open === 2 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our course offers a unique blend of comprehensive curriculum, practical hands-on training, and personalized mentorship.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(3)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Will I learn about the latest tools and techniques?
                            <Icon id={3} open={open} />
                        </div>
                        {open === 3 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, the course covers the latest tools, trends, and best practices in digital marketing.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(4)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What is the outcome of this course?
                            <Icon id={4} open={open} />
                        </div>
                        {open === 4 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You'll be prepared to pursue a career in digital marketing, equipped with the skills and certification needed to succeed.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(5)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How does the mentorship program work?
                            <Icon id={5} open={open} />
                        </div>
                        {open === 5 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You'll receive one-on-one mentorship sessions to help you navigate your learning journey and career goals.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(6)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Is there a flexible payment option?
                            <Icon id={6} open={open} />
                        </div>
                        {open === 6 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, we offer flexible payment plans to suit your financial needs.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(7)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Can I network with industry professionals?
                            <Icon id={7} open={open} />
                        </div>
                        {open === 7 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Absolutely. We offer networking opportunities through our exclusive community and events.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(8)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What are the key topics covered in this course?
                            <Icon id={8} open={open} />
                        </div>
                        {open === 8 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    The course covers a wide range of topics, including SEO, content marketing, social media, email marketing, PPC, analytics, and more.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(9)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How practical is the training?
                            <Icon id={9} open={open} />
                        </div>
                        {open === 9 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    The course emphasizes practical, hands-on training with real-world projects.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white drop-shadow-md shadow-md border-b border-gray-400  rounded-md cursor-pointer" onClick={() => handleOpen(10)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What certification will I receive?
                            <Icon id={10} open={open} />
                        </div>
                        {open === 10 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You'll receive a professional certification upon successful completion of the course
                                </p>
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default CourseOneFAQ;
