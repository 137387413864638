import React, { useState } from "react";

function HireTabs() {
    const [activeTab, setActiveTab] = useState("career");

    return (
        <div className="font-[poppins] flex bg-white border-t-2 border-gray-200 shadow-lg drop-shadow-lg w-full">
            <ul className="bg-gray-100 min-w-[190px] max-sm:min-w-[110px]">
                <li
                    className={`tab min-h-[100px] flex flex-col items-center text-center justify-center font-semibold w-full text-[11px] md:text-[15px] md:px-4 cursor-pointer ${activeTab === "career"
                        ? "text-blue-500 bg-white border-r-4 border-blue-500 font-bold"
                        : "text-gray-600 border-r-4 border-gray-100"
                        }`}
                    onClick={() => setActiveTab("career")}
                >
                    Talent Readiness
                </li>

                <li
                    className={`tab min-h-[100px] flex flex-col items-center text-center justify-center font-semibold w-full text-[11px] md:text-[15px] md:px-4  cursor-pointer ${activeTab === "guide"
                        ? "text-blue-500 bg-white border-r-4 border-blue-500 font-bold"
                        : "text-gray-600 border-r-4 border-gray-100"
                        }`}
                    onClick={() => setActiveTab("guide")}
                >
                    Career Guidance
                </li>
                <li
                    className={`tab min-h-[100px] flex flex-col items-center text-center justify-center font-semibold w-full text-[11px] md:text-[15px] md:px-4  cursor-pointer ${activeTab === "Placements"
                        ? "text-blue-500 bg-white border-r-4 border-blue-500 font-bold"
                        : "text-gray-600 border-r-4 border-gray-100"
                        }`}
                    onClick={() => setActiveTab("Placements")}
                >
                    Guided Program Exploration
                </li>
                <li
                    className={`tab min-h-[100px] flex flex-col items-center text-center justify-center font-semibold w-full text-[11px] md:text-[15px] md:px-4  cursor-pointer ${activeTab === "Practicals"
                        ? "text-blue-500 bg-white border-r-4 border-blue-500 font-bold"
                        : "text-gray-600 border-r-4 border-gray-100"
                        }`}
                    onClick={() => setActiveTab("Practicals")}
                >
                    Placement Assistance
                </li>
            </ul>


            <div className="px-2 md:px-6 py-4">
                {activeTab === "career" && (
                    <div>
                        <h3 className="text-[16px] md:text-[24px] font-bold text-center uppercase">Talent Readiness</h3>
                        <p className="text-[10px] md:text-[14px] text-gray-600 mt-2 md:mt-4 text-justify md:text-center  md:w-[80%] mx-auto">
                            At Thelearnbot.com, we ensure that our graduates are job-ready and aligned with your company’s needs. Our rigorous training
                            and assessment methods guarantee that our talent is equipped to deliver exceptional results from day one.
                        </p>

                        <div className="flex justify-center items-center mt-5 md:mt-12">
                            <ul className="grid grid-cols-1  md:grid-cols-2 gap-x-20 gap-y-4 ml-3 md:ml-0 md:list-inside md:gap-y-12 list-disc  text-[10px] md:text-[14px]  font-semibold">
                                <li>Comprehensive Training: All aspects of digital marketing.</li>
                                <li>Real-World Projects: Hands-on practical experience.</li>
                                <li>Expert Instructors: Learn from professionals.</li>
                                <li>Certification: Recognized by top employers.</li>
                                <li>Personalized Mentorship: One-on-one guidance provided.</li>
                                <li>Job Placement Support: Internships and job assistance.</li>

                            </ul>
                        </div>
                    </div>
                )}
                {activeTab === "guide" && (
                    <div>
                        <h3 className="text-[16px] md:text-[24px] font-bold text-center uppercase">Career Guidance</h3>
                        <p className="text-[10px] md:text-[14px]  text-gray-600 mt-2 md:mt-4 text-justify md:text-center  md:w-[80%] mx-auto">
                            Our expert guidance helps unlock your potential in the digital marketing world. We provide essential skills, strategies,
                            and insights to build a successful and rewarding career in this dynamic industry.
                        </p>

                        <div className="flex justify-center items-center mt-5 md:mt-12">
                            <ul className="grid grid-cols-1  md:grid-cols-2 gap-x-20 gap-y-4 ml-3 md:ml-0 md:list-inside md:gap-y-12 list-disc  text-[10px] md:text-[14px] font-semibold">
                                <li>Career Counseling: Personalized career advice.</li>
                                <li>Skill Development: Training in latest tools.</li>
                                <li>Industry Insights: Current trends and practices.</li>
                                <li>Resume Building: Crafting a compelling resume.</li>
                                <li>Interview Preparation: Tips and mock interviews.</li>
                                <li>Networking Opportunities: Connect with industry experts.</li>
                                
                            </ul>
                        </div>
                    </div>
                )}
                {activeTab === "Placements" && (
                    <div>
                        <h3 className="text-[16px] md:text-[24px] font-bold text-center uppercase">Guided Program </h3>
                        <p className="text-[10px] md:text-[14px] text-gray-600 mt-2 md:mt-4 text-justify md:text-center  md:w-[80%] mx-auto">
                        Explore our guided program to understand how our training can meet your specific needs. We offer a comprehensive overview, 
                        including free masterclasses and pre-enrollment consultations.
                        </p>

                        <div className="flex justify-center items-center mt-5 md:mt-12">
                            <ul className="grid grid-cols-1  md:grid-cols-2 gap-x-20 gap-y-4 ml-3 md:ml-0 md:list-inside md:gap-y-12 list-disc  text-[10px] md:text-[14px]  font-semibold">
                                <li>Program Overview: Detailed course structure.</li>
                                <li>Free Masterclass: Experience teaching methods.</li>
                                <li>Pre-Enrollment Consultation: 15-minute introductory call.</li>
                                <li>Course Customization: Tailored guidance available.</li>
                                <li>Trial Access: Preview course materials.</li>
                                <li>Effortless Onboarding: Simple enrollment process. </li>
                            
                            </ul>
                        </div>
                    </div>
                )}
                {activeTab === "Practicals" && (
                    <div>
                        <h3 className="text-[18px] md:text-[24px] font-bold text-center uppercase">Placement Assistance</h3>
                        <p className="text-[10px] md:text-[14px] text-gray-600 mt-2 md:mt-4 text-justify md:text-center  md:w-[80%] mx-auto">
                        Our placement assistance ensures that our graduates are well-prepared for the job market. We provide support throughout 
                        the hiring process to match talent with leading companies.
                        </p>

                        <div className="flex justify-center items-center mt-5 md:mt-12">
                            <ul className="grid grid-cols-1  md:grid-cols-2 gap-x-20 gap-y-4 ml-3 md:ml-0 md:list-inside md:gap-y-12 list-disc  text-[10px] md:text-[14px]  font-semibold">
                                <li>Job Placement Support: Resources for employment.</li>
                                <li>Internship Opportunities: Access to top internships.</li>
                                <li>Employer Network: Connections with employers.</li>
                                <li>Interview Scheduling: Coordination with companies.</li>
                                <li>Feedback and Improvement: Enhancing job readiness.</li>
                                <li>Post-Placement Follow-Up: Ongoing support after hiring.</li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HireTabs;
