import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { FaWhatsapp } from "react-icons/fa"; 


import logo from '../images/logo.png'
import black from '../images/logo.png'



const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };



    //color change
    const [navbarBg, setNavbarBg] = useState('bg-gray-200'); 


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-gray-50 shadow-md ');
            } else {
                setNavbarBg('bg-gray-200');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    // function clicklink() {
    //     setHide(false)
    // }


    return (
        <>

            {/* -------- second navbar ------- */}

            <nav className={` w-full sticky top-0 z-30  py-4  shadow-sm shadow-slate-400   md:py-8 ${navbarBg} `}>
                <div className="max-w-full mx-auto font-[poppins]  ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[90%] mx-auto'>


                        <div className='w-fit md:w-fit'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className=' w-[100%] sm:w-40 md:w-52 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-fit font-semibold gap-x-4 xl:gap-x-14' >


                            <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold ">Home</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="/course" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold ">Courses</button>
                                    </p>
                                </Link>
                            </li>


                            {/* <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px]'>

                                <div to="" smooth={true} duration={500} onClick={topPage} className='relative group'>

                                    <p className="relative group flex items-center">
                                        <button class="  hover:text-[#FF6F61]  focus:text-red-600  font-semibold">Courses</button>
                                    </p>

                                    <div
                                        class="absolute left-0 w-64 mt-0 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition duration-500 delay-300 py-2">
                                        <div class=" overflow-hidden">
                                            <Link to='web_design' >
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Web Developement</a>
                                            </Link>
                                            <Link to='web_design' >
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Web Developement</a>
                                            </Link>
                                            <Link to='web_design' >
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Web Developement</a>
                                            </Link>
                                            <Link to='web_design' >
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Web Developement</a>
                                            </Link>
                                            <Link to='web_design' >
                                                <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Web Developement</a>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </li> */}

                            {/* <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold">Blog</button>
                                    </p>
                                </Link>
                            </li> */}

                            <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="about" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold">About Us</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="blog" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold">Blog</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-gray-900  text-[12px] xl:text-[15px] ' >
                                <Link to="/contact" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold">Contact</button>
                                    </p>
                                </Link>
                            </li>

                            <li className='  cursor-pointer duration-300 text-gray-900   text-[12px] xl:text-[15px] ' >
                                <Link to="/hire-from-us" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="hover:text-[#FF6F61]  focus:text-red-600  font-semibold">Hire From Us</button>
                                    </p>
                                </Link>
                            </li>



                        </ul>

                        <div className='cursor-pointer ml-16 mt-1 md:mt-0 md:ml-0 w-full md:w-fit'>
                            {/* <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">

                                <div className=' w-6 h-6 md:h-8 md:w-8 bg-green-600 rounded-full duration-300 shadow-sm drop-shadow-md shadow-gray-100 hover:shadow-green-200 hover:shadow-sm hover:drop-shadow-md hover:scale-105 flex  justify-center items-center  '>
                                    <FaWhatsapp className=' rounded-full text-[16px] md:text-[21px]  text-white' />
                                </div>
                            </a> */}
                            <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">
                                <button className='font-semibold border border-[#6ebcba] hover:text-white md:tracking-[1px] px-3 md:px-6 py-1.5 md:py-2.5 uppercase rounded-md text-gray-50 hover:border hover:border-gray-200 bg-[#2384b3]  hover:bg-[#2e596e] duration-300  text-[10px] md:text-[14px]'> Enroll Now </button>
                            </a>
                        </div>


                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className='block lg:hidden'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-gray-950' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 text-gray-950' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'fixed lg:hidden left-0 top-0 w-[60%] h-[100%] border-r  bg-gray-50 ease-in-out duration-500'
                                        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                                }
                            >

                                {/* Mobile Logo */}

                                <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={black} className=' w-36 sm:w-40  pt-10  ml-10' alt="" onClick={handleNav} />
                                </Link>



                                {/* Mobile Navigation Items */}

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Home</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/course" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Courses</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/about" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>About Us</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/blog" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Blog</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/contact" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <Link to="/hire-from-us" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Hire From Us</button>
                                        </p>
                                    </Link>
                                </li>

                      


                            </ul>
                        }

                    </div>
                </div>
            </nav >

        </>
    )
}

export default Header