// PrivacyPolicy.js
import React from "react";

const PrivacyPolicy = () => {
    return (
        
        <>

            <div className="pt-10 md:pt-20  pb-10 md:pb-20 bg-gray-50">

                <div className="px-4 md:p-6 text-gray-900 font-[poppins]">
                    <div className="w-full md:w-[80%]  mx-auto">
                        <h1 className="text-[20px] md:text-[28px] font-bold mb-6 text-center uppercase">Privacy Policy</h1>

                        <div className="flex flex-col  gap-y-3 md:gap-y-6">
                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Information We Collect</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We may collect personal information such as your name, email address, phone number, and
                                    other contact details when you fill out forms on our website, subscribe to our newsletter, or contact us.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">How We Use Your Information</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We automatically collect data about how you use our website, such as your IP address, browser type, time
                                    spent on pages, and interactions with our site.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Cookies and Tracking</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We use cookies and similar tracking technologies to enhance your browsing experience. Cookies
                                    help us track how you use our website and remember your preferences.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Sharing Your Information</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We do not sell or rent your personal information. However, we may share your data with trusted third-party service
                                    providers who assist us in operating our website, conducting business, or serving you, provided those parties agree
                                    to keep your information confidential.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Data Security</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                    We take the protection of your data seriously and implement a variety of security measures to safeguard your personal
                                    information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee its absolute security.
                                </p>
                            </div>

                            <div className="">
                                <h2 className="text-[16px] md:text-[22px] font-semibold mb-2">Changes to This Policy</h2>
                                <p className="text-gray-700 text-[12px] md:text-[14px] mb-4">
                                We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated date.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default PrivacyPolicy;
