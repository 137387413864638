import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}

const Faq = () => {
  const [open, setOpen] = useState(null);

  const handleOpen = (value) => {
    setOpen(open === value ? null : value);
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-transparent  md:pt-6  md:px-6 w-full md:w-[95%] mx-auto font-[poppins]">

      <div className="grid grid-cols-1 md:grid-cols-2  gap-x-14 gap-y-3 md:gap-y-10">

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(1)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What courses do you offer?

              <Icon id={1} open={open} />
            </div>
            {open === 1 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800  text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  We offer a 4-month comprehensive course titled "Digital Marketing Excellence in 120 Days," covering all aspects of digital marketing.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(2)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              Who can join the course?
              <Icon id={2} open={open} />
            </div>
            {open === 2 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  Our courses are designed for Indian students, entrepreneurs, working professionals, and freelancers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(3)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What is the duration of the course?
              <Icon id={3} open={open} />
            </div>
            {open === 3 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  The course can be completed in 4 months, with flexible learning options.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(4)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What certification will I receive?
              <Icon id={4} open={open} />
            </div>
            {open === 4 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  Upon successful completion, you'll receive a professional certification recognized by top employers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(5)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              Is job assistance provided?
              <Icon id={5} open={open} />
            </div>
            {open === 5 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  Yes, we offer job placement support and access to internship opportunities.

                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(6)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What tools will I learn?
              <Icon id={6} open={open} />
            </div>
            {open === 6 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  You'll gain hands-on experience with 20+ digital marketing tools, including Google Analytics, SEMrush, and Hootsuite.
                </p>
              </div>
            )}
          </div>
        </div>


        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(7)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What are the class timings?
              <Icon id={7} open={open} />
            </div>
            {open === 7 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  We offer both weekday and weekend batches to accommodate your schedule.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(8)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              Do you provide lifetime access to course materials?
              <Icon id={8} open={open} />
            </div>
            {open === 8 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  Yes, students have lifetime access to course materials, updates, and exclusive webinars.
                </p>
              </div>
            )}
          </div>
        </div>


        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(9)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              How do I enroll?
              <Icon id={9} open={open} />
            </div>
            {open === 9 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  You can enroll online through our website or contact us for more details.
                </p>
              </div>
            )}
          </div>
        </div>

        <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
          <div className="bg-white drop-shadow-md shadow-md border-b border-gray-400  rounded-md cursor-pointer" onClick={() => handleOpen(10)}>
            <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
              What is the fee structure?
              <Icon id={10} open={open} />
            </div>
            {open === 10 && (
              <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                  Please contact us for detailed information on the course fees.
                </p>
              </div>
            )}
          </div>
        </div>


      </div>
    </div>
  );
};

export default Faq;
