import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

import logo from '../images/logo.png'

const AdminHeader = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className='bg-gray-900 py-6'>

            <div className='flex justify-between items-center w-[90%] mx-auto'>

                <div>
                    <img src={logo} className='w-48 object-cover' alt='none' />
                </div>

                <div>
                    <button onClick={handleLogout} className='bg-red-500 text-white px-4 py-2 rounded'>Logout</button>
                </div>


            </div>

        </div>
    )
}

export default AdminHeader
