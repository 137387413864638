import React from 'react'

import logo from '../images/logo.png'


//icons

import { FaSquareXTwitter } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { TbBrandWhatsapp } from "react-icons/tb";
import { FaPinterestP } from "react-icons/fa6";
import { TbBrandLinkedin } from "react-icons/tb";



import { MdOutlineMail } from "react-icons/md";
import { MdOutlinePhoneAndroid } from "react-icons/md";


import { Link } from 'react-router-dom';


const Footer = () => {

    function topPage() {
        window.scroll(0.0)
    }

    return (
        <div className='font-[poppins]'>

            <section>
                <div class="bg-gray-950">
                    <div class="w-full md:w-[90%] mx-auto  sm:px-6 text-gray-800 sm:grid md:grid-cols-4 sm:grid-cols-2 ">
                        <div class="px-4 md:px-5 pt-8 pb-4 md:py-10">

                            <div>
                                <img src={logo} className='w-48 md:w-60 object-cover' alt='none' />
                            </div>

                            <div>
                                <div className='flex items-center gap-x-5 md:gap-x-5 mt-8 text-gray-200'>
                                    <section class="flex justify-center items-center">
                                        <Link to='https://www.facebook.com/profile.php?id=61559751436051' target='_blank'>
                                            <button class="group flex justify-center p-2 rounded-md  bg-gray-100 drop-shadow-sm shadow-md border border-orange-500 text-gray-900  font-semibold hover:-translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                            >
                                                <FiFacebook className="text-[12px] sm:text-[14px] group-hover:text-[#f15b2d]" />

                                            </button>
                                        </Link>
                                    </section>
                                    <section class="flex justify-center items-center">
                                        <Link to="https://www.instagram.com/cinema_factory_academy/" target='_blank'>
                                            <button class="group flex justify-center p-2 rounded-md  bg-gray-100  drop-shadow-sm shadow-md border border-orange-500 text-gray-900 font-semibold hover:-translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                            >
                                                <BsInstagram className="text-[12px] sm:text-[14px] group-hover:text-[#f15b2d]" />

                                            </button>
                                        </Link>
                                    </section>
                                    <section class="flex justify-center items-center">
                                        <Link to='https://x.com/CF_academy2024?t=50Xz_jo1R8-TMc3gVJnwwQ&s=09' target='_blank'>
                                            <button class="group flex justify-center p-2 rounded-md  bg-gray-100 drop-shadow-sm shadow-md border border-orange-500 text-gray-900 font-semibold hover:-translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                            >
                                                <FaSquareXTwitter className="text-[12px] sm:text-[14px] group-hover:text-[#f15b2d]" />

                                            </button>
                                        </Link>
                                    </section>
                                    <section class="flex justify-center items-center">
                                        <Link to="https://www.threads.net/@cinema_factory_academy" target='_blank'>
                                            <button
                                                class="group flex justify-center p-2 rounded-md  bg-gray-100 drop-shadow-sm shadow-md border border-orange-500 text-gray-900 font-semibold hover:-translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                            >
                                                <TbBrandLinkedin className="text-[12px] sm:text-[14px] group-hover:text-[#f15b2d]" />

                                            </button>
                                        </Link>
                                    </section>
                                    <section class="flex justify-center items-center">
                                        <Link to="" target='_blank' className="">
                                            <button
                                                class="group flex justify-center p-2 rounded-md  bg-gray-100 drop-shadow-sm shadow-lg border border-orange-500 text-gray-900 font-semibold hover:-translate-y-3 hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                            >
                                                <FaPinterestP  className="text-[12px] sm:text-[14px] group-hover:text-[#f15b2d]" />
                                            </button>
                                        </Link>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class=" px-4 md:px-5 py-2 md:py-10">
                            <div class="text-[14px] md:text-[16px] uppercase text-[#f15b2d] font-bold">Resources</div>
                            <Link to='/' onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" > Home </p>
                            </Link>
                            <Link to="/course"  onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" > Courses </p>
                            </Link>
                            <Link to="about" onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" > About </p>
                            </Link>
                            <Link to="/contact" onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" > Contact </p>
                            </Link>
                        </div>

                        <div class=" px-4 md:px-5 py-2 md:py-10">
                            <div class="text-[14px] md:text-[16px] uppercase text-[#f15b2d] font-bold">Courses</div>
                            <Link to='/course/digital_marketing_excellence' onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" >Digital Marketing Excellence</p>
                            </Link>
                            <Link to='/course/digital_marketing_mastery' onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" >Digital Marketing Mastery</p>
                            </Link>
                            <Link to='/course/seo_mastery' onClick={topPage}>
                                <p class="my-3 block text-[12px] md:text-[14px] text-white hover:text-gray-300" >SEO Mastery</p>
                            </Link>
                        </div>

                        <div class=" px-4 md:px-5 py-2 md:py-10">
                            <div class="text-[14px] md:text-[16px] uppercase text-[#f15b2d] font-bold">Contact us</div>
                            <div>
                                <p class="my-3  text-[12px] md:text-[14px] text-white hover:text-gray-300 " href="/#">Thelearnbot.com, 123 Digital Avenue, Chennai, Tamil Nadu, 600001, India</p>
                            </div>
                            <div>
                                <a href="mailto:contact@cinemafactory.com" target="_blank" class="my-3  text-[12px] md:text-[14px] text-white hover:text-gray-300 flex items-center gap-x-1" > <span className='text-[18px]'><MdOutlineMail /></span> contact@thelearnbot.com  </a>
                            </div>
                            <div>
                                <a href="tel:+919884683888" target="_blank" class="my-3  text-[12px] md:text-[14px] text-white hover:text-gray-300 flex items-center gap-x-1" > <span className='text-[18px]'><MdOutlinePhoneAndroid /></span> +91 98765 43210  </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="bg-gray-950 ">
                    <div class="flex  flex-col-reverse md:flex-row justify-between   w-full md:w-[80%]  pb-2 md:pb-5 px-3 m-auto   md:pt-5 border-t text-gray-800  items-center">

                        <div class=" my-2 md:my-5 text-gray-300 text-[10px] md:text-[14px]">© LearnBot 2024. All Rights Reserved.</div>

                        <div className='flex items-center gap-x-3'>
                            {/* <Link>
                                <p class="my-3 block text-[11px] md:text-[12px] text-white hover:text-gray-300" >Careers</p>
                            </Link> */}
                            <Link to='/privacy-policy' onClick={topPage}>
                                <p class="my-3 block text-[11px] md:text-[12px] text-white hover:text-gray-300" >Privacy Policy</p>
                            </Link>
                            <Link to='/terms-conditions' onClick={topPage}>
                                <p class="my-3 block text-[11px] md:text-[12px] text-white hover:text-gray-300" >Terms & Conditions</p>
                            </Link>
                        </div>

                    </div>
                </div>


            </section>

        </div>
    )
}

export default Footer
