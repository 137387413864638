import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/api/auth/login', { username, password });
      localStorage.setItem('token', response.data.token);
      navigate('/admin');
    } catch (err) {
      alert('Login failed');
    }
  };

  return (
    <div className='flex justify-center items-center h-screen'>
      <form onSubmit={handleLogin} className='bg-white p-8 rounded shadow-md'>
        <h2 className='text-2xl mb-4'>Admin Login</h2>
        <input 
          type='text' 
          placeholder='Username' 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          className='mb-4 p-2 border'
        />
        <input 
          type='password' 
          placeholder='Password' 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          className='mb-4 p-2 border'
        />
        <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
