import React from 'react'

import aboutBanner from '../images/about/about.png'

import whyOne from '../images/about/why1.png'
import whyTwo from '../images/about/why2.png'


//icons
import { GiBullseye } from "react-icons/gi";
import { TbEyeStar } from "react-icons/tb";


const About = () => {
    return (
        <div className='font-[montserrat]'>

            <section>

                <div className=' '>

                    <div className=' w-full'>
                        <img src={aboutBanner} className='w-full object-cover' alt='none' />

                    </div>


                </div>
            </section>


            {/* -------------------- Who We Are ----------------------- */}

            <section className='pt-6 md:pt-20 pb-10 md:pb-20 font-[poppins]'>

                <div className='w-full px-4 md:w-[80%] mx-auto'>

                    <div className='flex flex-col items-center gap-y-2'>
                        <h3 className='font-bold text-black text-[20px] md:text-[28px] uppercase'>About US</h3>
                        <p className='text-[10px] md:text-[16px] font-[roboto] text-justify md:text-center w-full md:w-[90%] mx-auto'>
                            Thelearnbot.com  is a premier Digital Marketing Training Institute in Chennai, dedicated to empowering individuals with the
                            skills needed to excel in the digital world. With over two decades of industry experience, our professionals deliver a
                            comprehensive curriculum that covers everything from foundational concepts to advanced techniques.
                        </p>
                    </div>

                    <div className='flex justify-center items-center  py-6 md:py-20'>
                        <div className='grid grid-cols-2 md:grid-cols-4 gap-y-10 gap-x-12 md:gap-x-16'>

                            <div className="" >
                                <div className=' z-[-2] bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px]  rounded-t-md py-6 md:py-20  px-6 md:px-16 border-b-2  md:border-b-4 border-orange-600 hover:border-b-transparent hover:border-t-4 hover:border-orange-600 rotate-[5deg] hover:rotate-[-5deg] duration-300 '>
                                    <div className=' flex justify-center items-center'>
                                        <h2 className='text-center text-white font-semibold  text-[14px] md:text-[18px]'>Innovation</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="" >
                                <div className=' z-[-2] bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px] rounded-t-md   py-6 md:py-20  px-6 md:px-16 border-b-2  md:border-b-4 border-orange-600 hover:border-b-transparent hover:border-t-4 hover:border-orange-600 rotate-[5deg] hover:rotate-[-5deg] duration-300 '>
                                    <div className=' flex justify-center items-center'>
                                        <h2 className='text-center text-white font-semibold  text-[14px] md:text-[18px]'>Diversity</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="" >
                                <div className=' z-[-2] bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px] rounded-t-md  py-6 md:py-20  px-6 md:px-16 border-b-2  md:border-b-4 border-orange-600 hover:border-b-transparent hover:border-t-4 hover:border-orange-600 rotate-[5deg] hover:rotate-[-5deg] duration-300 '>
                                    <div className=' flex justify-center items-center'>
                                        <h2 className='text-center text-white font-semibold  text-[14px] md:text-[18px]'>Excellence</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="" >
                                <div className=' z-[-2] bg-[#000000] bg-[radial-gradient(#ffffff33_1px,#00091d_1px)] bg-[size:20px_20px]  rounded-t-md  py-6 md:py-20  px-6 md:px-16 border-b-2  md:border-b-4 border-orange-600 hover:border-b-transparent hover:border-t-4 hover:border-orange-600 rotate-[5deg] hover:rotate-[-5deg] duration-300 '>
                                    <div className=' flex justify-center items-center'>
                                        <h2 className='text-center text-white font-semibold  text-[14px] md:text-[18px]'>Integrity</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p className='text-[12px] md:text-[18px]  font-normal md:font-semibold  text-justify md:text-center font-[poppins]'>
                            Our mission is to provide high-quality training that equips students with the knowledge and practical
                            experience needed to secure rewarding careers in digital marketing. We strive to be the go-to institute
                            for anyone looking to master digital marketing and achieve professional success.
                        </p>
                    </div>

                </div>
            </section>


            {/* ----------------- our mission ------------------------- */}

            <section className=' pt-10 md:pt-20  pb-10 md:pb-20 bg-[#F5F5F5] font-[poppins]'>

                <div className='w-full px-4 md:w-[70%] mx-auto'>

                    <div className='flex justify-center items-center'>

                        <div className='grid  grid-cols-1 gap-y-12 md:grid-cols-2 gap-x-28'>

                            <div>
                                <div className='flex flex-col gap-y-5 justify-center items-center bg-white px-2  md:px-6 py-4 rounded-md shadow-sm drop-shadow-sm hover:shadow-md hover:drop-shadow-md duration-300'>

                                    <div className=''>
                                    <TbEyeStar className='text-sky-700 text-[60px] object-cover' />
                                    </div>

                                    <div className='flex flex-col gap-y-1 md:gap-y-3'>
                                        <h2 className='font-bold text-[14px] md:text-[18px] text-center uppercase text-orange-500'> <span className='text-black'>Our</span> Vision</h2>
                                        <p className='text-center text-[11px] md:text-[14px] font-normal  font-[roboto] text-gray-800'>
                                            To be a global leader in digital marketing education, fostering innovation,  and
                                            excellence in every student, empowering them to excel in the dynamic digital landscape.
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div className='flex flex-col gap-y-5 justify-center items-center bg-white px-2  md:px-6 py-4 rounded-md shadow-sm drop-shadow-sm hover:shadow-md hover:drop-shadow-md duration-300'>

                                    <div className=''>
                                      
                                        <GiBullseye className='text-sky-700 text-[60px] object-cover' />
                                    </div>

                                    <div className='flex flex-col gap-y-1 md:gap-y-3'>
                                        <h2 className='font-bold text-[14px]  md:text-[18px] text-center uppercase text-orange-500'><span className='text-black'>Our</span> Mission</h2>
                                        <p className='text-center  text-[11px] md:text-[14px] font-normal  font-[roboto] text-gray-800'>
                                            To deliver industry-relevant, hands-on training that enables students to thrive in
                                            the digital marketing landscape and secure meaningful employment.
                                        </p>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>


            </section>


            {/* ---------------------- Why Choose Us? ----------------------- */}

            <section className='pt-10 md:pt-20 pb-10 md:pb-20 bg-[#f0f8ff] font-[poppins]'>

                <div className='w-full px-4 md:w-[80%] mx-auto'>


                    <div className='flex flex-col gap-y-2 items-center justify-center mb-6 md:mb-20'>
                        <h2 className='font-bold  text-[18px] md:text-[34px] uppercase'>Why Choose <span className='text-[#f15b2d]'>Us?</span> </h2>
                        <p className='text-center  text-[11px] md:text-[14px] font-[roboto]'>Welcome to LearnBot, where we are passionate about empowering individuals and businesses with the digital marketing skills they need to thrive in today's online landscape. Our expert instructors</p>
                    </div>

                    <div className='flex flex-col  gap-y-8 md:gap-y-20'>

                        <div className='flex flex-col  md:flex-row justify-center items-center  gap-y-6 md:gap-y-12'>

                            <div className='w-full md:w-[50%]'>

                                <div className='flex justify-center items-center' >
                                    <img src={whyOne} className=' w-full md:w-[80%] object-cover' alt='why' title='learn bot' />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%]'>

                                <div className=' flex flex-col  gap-y-3 md:gap-y-6'>

                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Comprehensive Curriculum </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Our courses cover every aspect of digital marketing, from basics to advanced techniques, ensuring you receive a well-rounded education.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Industry-Experienced Instructors </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Learn from professionals with over two decades of experience in the digital marketing industry.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Practical, Hands-On Training </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Engage in real-world projects and practical exercises that allow you to apply your learning effectively.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Certification </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Upon successful completion, receive a professional certification recognized by top employers and industry leaders.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Flexible Learning Options </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Choose between a 2-month intensive course or a 4-month comprehensive course to fit your schedule and learning pace. </p>
                                    </div>


                                </div>

                            </div>

                        </div>


                        <div className='flex flex-col-reverse  md:flex-row justify-center items-center gap-y-6 md:gap-y-12 '>



                            <div className='w-full md:w-[50%]'>

                                <div className=' flex flex-col gap-y-3 md:gap-y-6'>

                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Personalized Mentorship </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Benefit from one-on-one mentorship sessions to address your unique learning needs and career goals.</p>
                                    </div>
                                
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Networking Opportunities </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Connect with peers, industry experts, and alumni through our exclusive community and events.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Job Assistance and Internships </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '> Gain access to job placement support and internship opportunities with leading companies.</p>
                                    </div>
                                    <div className='flex flex-col gap-y-1 md:gap-y-2'>
                                        <h3 className='text-[14px] md:text-[16px] font-semibold '>Lifetime Access to Resources </h3>
                                        <p className='text-[12px] md:text-[14px] font-[roboto] '>Enjoy lifetime access to course materials, updates, and exclusive webinars to continue your learning journey.</p>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full md:w-[50%]'>

                                <div className='flex  justify-center md:justify-end'>
                                    <img src={whyTwo} className='w-full md:w-[80%] object-cover' alt='why' title='learn bot' />
                                </div>
                            </div>

                        </div>


                    </div>




                </div>

            </section>


        </div>
    )
}

export default About
