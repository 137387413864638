import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
        </svg>
    );
}

const CourseFAQ = () => {
    const [open, setOpen] = useState(null);

    const handleOpen = (value) => {
        setOpen(open === value ? null : value);
    };

    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <div className="bg-transparent  md:pt-6  md:px-6 w-full md:w-[95%] mx-auto font-[poppins]">

            <div className="grid grid-cols-1 md:grid-cols-2  gap-x-14 gap-y-3 md:gap-y-10">

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(1)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What will I learn in this course?

                            <Icon id={1} open={open} />
                        </div>
                        {open === 1 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800  text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    The course covers everything from SEO, content marketing, and social media to PPC, analytics, and digital marketing strategy.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(2)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Are there any prerequisites?
                            <Icon id={2} open={open} />
                        </div>
                        {open === 2 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    No prior experience is required. Our course is designed to accommodate beginners as well as those with some experience.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(3)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What kind of projects will I work on?
                            <Icon id={3} open={open} />
                        </div>
                        {open === 3 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You'll work on real-world projects that simulate actual digital marketing campaigns and strategies.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(4)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Who are the instructors?
                            <Icon id={4} open={open} />
                        </div>
                        {open === 4 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Our instructors are seasoned professionals with over two decades of experience in the digital marketing industry.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(5)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Can I attend the course online?
                            <Icon id={5} open={open} />
                        </div>
                        {open === 5 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, we offer both online and in-person learning options.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(6)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            How is the course structured?
                            <Icon id={6} open={open} />
                        </div>
                        {open === 6 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    The course is divided into four months, each focusing on different aspects of digital marketing, from foundational concepts to advanced techniques.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(7)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Will I have access to mentorship?
                            <Icon id={7} open={open} />
                        </div>
                        {open === 7 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, personalized mentorship is provided to address individual learning needs and career goals.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(8)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What is the course schedule?
                            <Icon id={8} open={open} />
                        </div>
                        {open === 8 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    The course is divided into 16 weeks, with a detailed syllabus covering all key areas of digital marketing.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white  drop-shadow-md shadow-md border-b border-gray-400  rounded-md  cursor-pointer" onClick={() => handleOpen(9)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            Is there a final project?
                            <Icon id={9} open={open} />
                        </div>
                        {open === 9 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    Yes, the course concludes with a capstone project where you'll apply all the techniques you've learned.
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div data-aos='fade-down-left' data-aos-delay="50" data-aos-duration="1000">
                    <div className="bg-white drop-shadow-md shadow-md border-b border-gray-400  rounded-md cursor-pointer" onClick={() => handleOpen(10)}>
                        <div className="flex justify-between items-center text-[10px] sm:text-[14px] md:text-[14px] text-gray-900 px-3 py-4 font-semibold">
                            What kind of support do I get post-completion?
                            <Icon id={10} open={open} />
                        </div>
                        {open === 10 && (
                            <div className=" font-normal text-blue-gray-800  border-b border-gray-500 rounded-sm px-3">
                                <p className="text-gray-800 text-[10px] sm:text-[13px] md:text-[14px] font-normal pb-3">
                                    You'll have access to job assistance, internships, and lifetime access to course resources.
                                </p>
                            </div>
                        )}
                    </div>
                </div>


            </div>
        </div>
    );
};

export default CourseFAQ;
